import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  generateQueryString,
  getErrorMessage,
  getStandardError,
} from "@qlibs/react-components";
import {
  Alert,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
} from "../../user/components/CustomFormItem";
import { httpRequest } from "../../../helpers/api";
import SectionContent from "../../../components/SectionContent";
import { AssetProperties } from "../../asset/types/asset.type";
import {
  ICreateReportTemplate,
  initialReportEmailTemplate,
  ReportEmailTemplateProperties,
} from "../types/report.type";
import useMasterData from "../../../hooks/useMasterData";
import { UserProperties } from "../../../services/openapi";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";

const { TextArea } = Input;

interface ILocation {
  templateId: string;
}

const MasterReportEmailTemplateEdit = () => {
  const navigate = useNavigate();
  const { templateId } = useParams<keyof ILocation>() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [data, setData] = useState<ReportEmailTemplateProperties>();
  const [users, setUsers] = useState<UserProperties[]>([]);
  const [companyId, setCompanyId] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [form] = Form.useForm();

  const { companies, getListCompanies } = useMasterData();

  const [template, setTemplate] = useState<ReportEmailTemplateProperties>(
    initialReportEmailTemplate
  );
  const [isExportHTML, setIsExportHTML] = useState<boolean>(false);

  const emailEditorRef = useRef<EditorRef>(null);

  const unlayer = emailEditorRef.current?.editor;

  const exportHtmlAndDesign = (): Promise<any> => {
    return new Promise((resolve) => {
      emailEditorRef.current?.editor?.exportHtml((data) => {
        let { design, html } = data;

        resolve({ html, design });
      });
    });
  };

  const downloadFileHtml = () => {
    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);

      const blob = new Blob([html], { type: "text/html" });

      const link = document.createElement("a");

      link.download = "template_email.html";

      link.href = window.URL.createObjectURL(blob);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    });
  };

  const onReady: EmailEditorProps["onReady"] = (unlayer: any) => {
    if (template.designContent) {
      unlayer.loadDesign(JSON.parse(template.designContent));
    }
  };

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning("This data has been archived and cannot be edited");
      return;
    }

    setIsLoadingAction(true);

    try {
      await form.validateFields();
    } catch (error: any) {
      console.error(error);
      setIsLoadingAction(false);
      return;
    }

    try {
      const { html, design } = await exportHtmlAndDesign();

      if (html && design) {
        const updatedTemplate = {
          templateName: values.templateName,
          subject: values.emailSubject,
          externalId: values.companyId,
          status: values.status,
          htmlContent: html,
          designContent: JSON.stringify(design),
        };
        setTemplate(updatedTemplate);

        console.log(updatedTemplate);

        let res: any;

        if (template.templateId) {
          res = await httpRequest.patch(
            `/template-messages/${template.templateId}`,
            updatedTemplate
          );
        } else {
          res = await httpRequest.post(`/template-messages`, updatedTemplate);
        }

        if (res) {
          message.success(
            `Success ${templateId ? "edit" : "create"} template email`
          );

          navigate("/master-report");

          form.resetFields();
          // setTemplate(initialTemplateMessages);
        }
      } else {
        message.error(`HTML Content is required`);
      }
    } catch (error) {
      console.log(error);
      message.error(getErrorMessage(error));
    } finally {
      setIsLoadingAction(false);
    }
  };

  useEffect(() => {
    if (templateId) {
      const fetchTemplate = async () => {
        try {
          const res: any = await httpRequest.get<any>(
            "/template-messages/" + templateId
          );

          if (res && res.data) {
            const { templateName, subject, externalId, status } =
              res.data.payload;

            setTemplate(res.data.payload);

            const values = {
              templateName,
              emailSubject: subject,
              companyId: externalId,
              status,
            };

            form.setFieldsValue(values);
          } else {
            message.error("Something went wrong");
          }
        } catch (error) {}
      };

      fetchTemplate();
    }
  }, [templateId]);

  const companyMapped = React.useMemo(() => {
    return companies.map((item) => ({
      value: item.companyId,
      label: item.name,
    }));
  }, [companies]);

  useEffect(() => {
    getListCompanies();
  }, []);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={`${templateId ? "Update" : "Add"} Master Report Email Template`}
        subtitle={`${
          templateId ? "Update" : "Add"
        } current master report email template`}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        {data?.deletedAt ? (
          <Alert
            className="mb-5"
            type="warning"
            message="This data has been archived and cannot be edited"
          />
        ) : (
          false
        )}
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about email template"
          >
            <Row>
              <CustomFormItemPer2
                label="Template Name"
                name="templateName"
                rules={generateFormRules("Template Name", ["required"])}
              >
                <Input placeholder="Input template name" />
              </CustomFormItemPer2>

              <CustomFormItemPer2
                label="Company"
                name="companyId"
                rules={generateFormRules("Company", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Choose company"
                  style={{ width: "100%" }}
                  options={companyMapped}
                  onChange={(e) => {
                    setCompanyId(e);
                    form.setFieldValue("userId", undefined);
                    setUserId("");
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer2>
              <CustomFormItemPer1
                label="Email Subject"
                name="emailSubject"
                rules={generateFormRules("Email Subject", ["required"])}
              >
                <TextArea rows={5} placeholder="Input email subject" />
              </CustomFormItemPer1>
            </Row>
          </SectionContent>

          <Row style={{ marginTop: -20 }}>
            <CustomFormItemPer1 label="Email Body" required>
              <EmailEditor ref={emailEditorRef} onReady={onReady} />
            </CustomFormItemPer1>
          </Row>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="These are device information, you can change anything"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default MasterReportEmailTemplateEdit;
