import { Avatar, Image, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import config, { APP_LOGO_SRC, APP_LOGO_SRC_COLLAPSED } from '../const/config';

type AppLogoProps = {
  collapsed: boolean;
};

const AppLogo: React.FC<AppLogoProps> = ({ collapsed }) => {
  return (
    <LogoContainer collapsed={collapsed}>
      {collapsed && config.sidebar.logoType === 'image-and-text' ? (
        <Image
          preview={false}
          width={50}
          src={APP_LOGO_SRC_COLLAPSED}
          style={{
            objectFit: 'cover',
          }}
        />
      ) : (
        <AppTitle>
          {config.sidebar.logoType === 'image-and-text' && (
            <Image preview={false} width={80} src={APP_LOGO_SRC} />
          )}
        </AppTitle>
      )}
    </LogoContainer>
  );
};

type LogoContainerProps = {
  collapsed: boolean;
};

const LogoContainer = styled.div<LogoContainerProps>`
  padding: ${({ collapsed }) => (collapsed ? '0' : '0px 24px')};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'flex-start')};
`;

const AppTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const AppAvatar = styled(Avatar)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export default AppLogo;
