import { generateQueryString, getErrorMessage } from "@qlibs/react-components";
import React, { useState } from "react";
import { httpRequest } from "../helpers/api";
import { CompanyProperties } from "../screens/company/types/company.type";
import { GroupProperties } from "../screens/group/types/group.type";
import { AssetProperties } from "../screens/asset/types/asset.type";
import { DeviceProperties } from "../screens/device/types/device.type";

const useMasterData = () => {
  const [groups, setGroups] = useState<GroupProperties[]>([]);
  const [companies, setCompanies] = useState<CompanyProperties[]>([]);
  const [assets, setAssets] = useState<AssetProperties[]>([]);
  const [devices, setDevices] = useState<DeviceProperties[]>([]);

  const getListGroup = async (params?: any) => {
    try {
      const res = await httpRequest.get(`/group${generateQueryString(params)}`);

      if (res.data.payload.results) {
        console.log(res.data.payload.results);
        setGroups(res.data.payload.results);
      }
    } catch (error) {
      console.log(getErrorMessage(error));
    }
  };

  const getListCompanies = async (params?: any) => {
    try {
      const res = await httpRequest.get(
        `/company${generateQueryString(params)}`
      );

      if (res.data.payload.results) {
        console.log(res.data.payload.results);
        setCompanies(res.data.payload.results);
      }
    } catch (error) {
      console.log(getErrorMessage(error));
    }
  };

  const getListAssets = async (params?: any) => {
    try {
      const res = await httpRequest.get(`/asset${generateQueryString(params)}`);

      if (res.data.payload.results) {
        console.log(res.data.payload.results);
        setAssets(res.data.payload.results);
      }
    } catch (error) {
      console.log(getErrorMessage(error));
    }
  };

  const getListDevices = async (params?: any) => {
    try {
      const res = await httpRequest.get(
        `/device${generateQueryString(params)}`
      );

      if (res.data.payload.results) {
        console.log(res.data.payload.results);
        setDevices(res.data.payload.results);
      }
    } catch (error) {
      console.log(getErrorMessage(error));
    }
  };

  return {
    groups,
    companies,
    assets,
    devices,
    getListGroup,
    getListCompanies,
    getListAssets,
    getListDevices,
  };
};

export default useMasterData;
