export type DeviceTypeProperties = {
  deviceTypeId: string;
  name: string;
  status: string;
  createdByUserId: string;
  userCreator?: {
    name?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
  };
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
};

export const initialDeviceType: DeviceTypeProperties = {
  deviceTypeId: "",
  name: "",
  status: "",
  createdByUserId: "",
};

export interface ICreateDeviceType {
  name: string;
  status: string;
}
