import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import {
  Alert,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
} from "../user/components/CustomFormItem";
import { httpRequest } from "../../helpers/api";
import SectionContent from "../../components/SectionContent";
import { AssetProperties } from "../asset/types/asset.type";
import {
  ICreateOperationMode,
  OperationModeProperties,
} from "./types/operationMode.type";
import dayjs from "dayjs";
import useMasterData from "../../hooks/useMasterData";

interface ILocation {
  operationModeId: string;
}

const OperationModeEdit = () => {
  const navigate = useNavigate();
  const { operationModeId } = useParams<keyof ILocation>() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [data, setData] = React.useState<OperationModeProperties>();
  const [form] = Form.useForm();

  const [groupId, setGroupId] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [assetId, setAssetId] = useState<string>("");
  const [deviceId, setDeviceId] = useState<string>("");

  const {
    getListGroup,
    getListCompanies,
    getListAssets,
    getListDevices,
    groups,
    companies,
    assets,
    devices,
  } = useMasterData();

  async function updateOperationMode(values: ICreateOperationMode) {
    try {
      setIsLoadingAction(true);
      const formData = {
        groupId: values.groupId,
        companyId: values.companyId,
        assetId: values.assetId,
        deviceId: values.deviceId,
        startAt: dayjs(values.startAt),
        status: values.status ? "active" : "inactive",
      };

      console.log(formData);
      // return;

      await httpRequest.patch<BaseResponseProps<OperationModeProperties>>(
        "/operation-mode/" + operationModeId,
        formData
      );

      message.success(`Success update operation mode`);
      form.resetFields();
      setData(undefined);
      navigate("/operation-mode");
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning("This data has been archived and cannot be edited");
    } else {
      updateOperationMode(values);
    }
  };

  async function fetchData(operationModeId: string) {
    try {
      const response = await httpRequest.get<
        BaseResponseProps<OperationModeProperties>
      >("/operation-mode/" + operationModeId);
      if (response.data.payload) {
        const data = response.data.payload;

        setData(data);

        const formData = {
          ...data,
          groupId: data.group?.groupId,
          companyId: data?.company?.companyId,
          assetId: data?.asset?.assetId,
          deviceId: data?.device?.deviceId,
          startAt: dayjs(data.startAt),
          status: data.status === "active" ? true : false,
        };
        form.setFieldsValue(formData);

        setGroupId(String(data?.group?.groupId));
        setCompanyId(String(data?.company?.companyId));
        setAssetId(String(data?.asset?.assetId));
        setDeviceId(String(data?.device?.deviceId));
      }
    } catch (error) {
      message.error(`Failed get operation mode data`);
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  useEffect(() => {
    if (operationModeId) {
      fetchData(operationModeId);
    }
  }, [operationModeId]);

  useEffect(() => {
    getListGroup();
  }, []);

  useEffect(() => {
    if (groupId)
      getListCompanies({
        filterGroupIds: groupId,
      });
  }, [groupId]);

  useEffect(() => {
    if (companyId)
      getListAssets({
        filterCompanyIds: companyId,
      });
  }, [companyId]);

  useEffect(() => {
    if (assetId)
      getListDevices({
        filterAssetIds: assetId,
      });
  }, [assetId]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Update Operation Mode"}
        subtitle={"Update current operation mode"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        {data?.deletedAt ? (
          <Alert
            className="mb-5"
            type="warning"
            message="This data has been archived and cannot be edited"
          />
        ) : (
          false
        )}
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about operation mode"
          >
            <Row>
              <CustomFormItemPer2
                label="Group"
                name="groupId"
                rules={generateFormRules("Group", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Search group"
                  style={{ width: "100%" }}
                  // onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => {
                    setGroupId(value);

                    setCompanyId("");
                    setAssetId("");
                    setDeviceId("");

                    form.setFieldValue("companyId", null);
                    form.setFieldValue("assetId", null);
                    form.setFieldValue("deviceId", null);
                  }}
                  allowClear
                  // onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={groups.map((item) => ({
                    value: item.groupId,
                    label: item.name,
                  }))}
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="Company"
                name="companyId"
                rules={generateFormRules("Company", ["required"])}
              >
                <Select
                  showSearch
                  disabled={groupId ? false : true}
                  placeholder="Search company"
                  style={{ width: "100%" }}
                  // onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => {
                    setCompanyId(value);

                    setAssetId("");
                    setDeviceId("");

                    form.setFieldValue("assetId", null);
                    form.setFieldValue("deviceId", null);
                  }}
                  allowClear
                  // onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={companies.map((item) => ({
                    value: item.companyId,
                    label: item.name,
                  }))}
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="Asset"
                name="assetId"
                rules={generateFormRules("Asset", ["required"])}
              >
                <Select
                  showSearch
                  disabled={companyId ? false : true}
                  placeholder="Search asset"
                  style={{ width: "100%" }}
                  // onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => {
                    setAssetId(value);

                    setDeviceId("");

                    form.setFieldValue("deviceId", null);
                  }}
                  allowClear
                  // onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={assets.map((item) => ({
                    value: item.assetId,
                    label: item.name,
                  }))}
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="Device Unique ID"
                name="deviceId"
                rules={generateFormRules("Device Unique ID", ["required"])}
              >
                <Select
                  showSearch
                  disabled={assetId ? false : true}
                  placeholder="Search device unique id"
                  style={{ width: "100%" }}
                  // onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => setDeviceId(value)}
                  allowClear
                  // onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={devices.map((item) => ({
                    value: item.deviceId,
                    label: item.uniqueId,
                  }))}
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2 label="Start Date" name="startAt">
                <DatePicker style={{ width: "100%" }} />
              </CustomFormItemPer2>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="These are operation mode information, you can change anything"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default OperationModeEdit;
