import {
  BaseResponseProps,
  HeaderSection,
  IHttpResponse,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import {
  Alert,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useEffect } from "react";
import SectionContent from "../../components/SectionContent";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
  CustomFormItemPer3,
} from "../user/components/CustomFormItem";
import useAddress from "../../hooks/useAddress";
import { httpRequest } from "../../helpers/api";
import { GroupProperties, ICreateGroup } from "./types/group.type";

interface ILocation {
  groupId: string;
}

const GroupEdit = () => {
  const navigate = useNavigate();
  const { groupId } = useParams<keyof ILocation>() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [data, setData] = React.useState<GroupProperties>();

  const [provinceId, setProvinceId] = React.useState<string>("");
  const [cityId, setCityId] = React.useState<string>("");
  const [districtId, setDistrictId] = React.useState<string>("");

  const [form] = Form.useForm();
  const {
    fetchRegion,
    provinces,
    districts,
    cities,
    activeProvince,
    activeCity,
    searchAddress,
    setActiveCity,
    setActiveProvince,
  } = useAddress();

  async function updateGroup(values: ICreateGroup) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        nickname: values.nickname,
        phoneNumber: values.phoneNumber,
        address: {
          address: values.address,
          provinceId: provinceId,
          cityId: cityId,
          districtId: districtId,
        },
        fax: values.fax,
        status: values.status ? "active" : "inactive",
      };

      console.log(formData);
      // return;
      await httpRequest.patch("/group/" + groupId, formData);

      message.success(`Success update ${values.name}`);
      form.resetFields();
      navigate("/group");
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning("This data has been archived and cannot be edited");
    } else {
      updateGroup(values);
    }
  };

  async function fetchData(groupId: string) {
    try {
      const response = await httpRequest.get<
        BaseResponseProps<GroupProperties>
      >("/group/" + groupId);
      if (response.data.payload) {
        const data = response.data.payload;
        setData(data);
        const formData = {
          ...data,
          address: data.address?.address,
          province: {
            value: data.address?.province.id,
            label: data.address?.province.value,
          },
          city: {
            value: data.address?.city.id,
            label: data.address?.city.value,
          },
          district: {
            value: data.address?.district.id,
            label: data.address?.district.value,
          },
          status: data.status === "active" ? true : false,
        };

        setProvinceId(String(data?.address?.province?.id));
        setCityId(String(data?.address?.city?.id));
        setDistrictId(String(data?.address?.district?.id));

        setActiveProvince(data?.address?.province?.id);
        setActiveCity(data?.address?.city?.id);

        form.setFieldsValue(formData);
        console.log(formData);
      }
    } catch (error) {
      message.error(`Failed get group data`);
    } finally {
      setIsLoadingAction(false);
    }
  }

  useEffect(() => {
    if (groupId) {
      fetchData(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    fetchRegion("provinces");
  }, []);

  useEffect(() => {
    fetchRegion("cities");
    fetchRegion("districts");
  }, [activeProvince, activeCity]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Edit Group"}
        subtitle={"Edit current group"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        {data?.deletedAt ? (
          <Alert
            className="mb-5"
            type="warning"
            message="This data has been archived and cannot be edited"
          />
        ) : (
          false
        )}
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
          disabled={isLoadingAction}
        >
          <SectionContent
            groupTitle={`Information`}
            subTitle="General infromation about this group"
          >
            <CustomFormItemPer1
              label="Group Name"
              name="name"
              rules={generateFormRules("Group Name", ["required"])}
            >
              <Input placeholder="Input group name" />
            </CustomFormItemPer1>
            <Row>
              <CustomFormItemPer3
                label="Group Nickname"
                name="nickname"
                rules={generateFormRules("Group Nickname", ["required"])}
              >
                <Input placeholder="Input group nickname" />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="Phone Number"
                name="phoneNumber"
                rules={generateFormRules("Group Phone Number", ["phoneNumber"])}
              >
                <Input placeholder="Input group phone number contact" />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="Fax"
                name="fax"
                rules={generateFormRules("Fax", ["numeric"])}
              >
                <Input placeholder="Input group fax contact" />
              </CustomFormItemPer3>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Address`}
            subTitle="Information about address of this group"
          >
            <CustomFormItemPer1
              label="Address"
              name="address"
              rules={generateFormRules("Address", ["required"])}
            >
              <Input placeholder="Input address" />
            </CustomFormItemPer1>
            <Row>
              <CustomFormItemPer3
                label="Province"
                name="province"
                rules={generateFormRules("Province", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Search province"
                  style={{ width: "100%" }}
                  onSearch={(value) => searchAddress("provinces", value)}
                  onChange={(value) => {
                    setActiveProvince(value);
                    setProvinceId(String(value));
                    setCityId("");
                    setDistrictId("");

                    form.setFieldsValue({
                      city: "",
                      district: "",
                    });
                  }}
                  allowClear
                  onClear={() => searchAddress("provinces", "")}
                  filterOption={false}
                  options={provinces.map((item) => ({
                    value: item.provinceId,
                    label: item.provinceName,
                  }))}
                />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="City"
                name="city"
                rules={generateFormRules("City", ["required"])}
              >
                <Select
                  showSearch
                  disabled={form.getFieldValue("province") ? false : true}
                  placeholder="Search cities"
                  style={{ width: "100%" }}
                  onSearch={(value) => searchAddress("cities", value)}
                  onChange={(value) => {
                    setActiveCity(value);
                    setCityId(String(value));
                    setDistrictId("");

                    form.setFieldsValue({
                      city: value,
                      district: "",
                    });
                  }}
                  allowClear
                  onClear={() => searchAddress("cities", "")}
                  filterOption={false}
                  options={cities.map((item) => ({
                    value: item.cityId,
                    label: item.cityName,
                  }))}
                />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="District"
                name="district"
                rules={generateFormRules("District", ["required"])}
              >
                <Select
                  showSearch
                  disabled={form.getFieldValue("city") ? false : true}
                  placeholder="Search district"
                  style={{ width: "100%" }}
                  onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => {
                    setActiveCity(value);
                    setDistrictId(String(value));

                    form.setFieldsValue({
                      district: value,
                    });
                  }}
                  allowClear
                  onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={districts.map((item) => ({
                    value: item.districtId,
                    label: item.districtName,
                  }))}
                />
              </CustomFormItemPer3>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="Setting about this group"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default GroupEdit;
