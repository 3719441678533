import {
  HeaderSection,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import { Card, Divider, Form, Input, Row, Select, Switch, message } from "antd";
import React, { useEffect } from "react";
import SectionContent from "../../components/SectionContent";
import { useNavigate } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
  CustomFormItemPer3,
} from "../user/components/CustomFormItem";
import useAddress from "../../hooks/useAddress";
import { httpRequest } from "../../helpers/api";
import { ICreateGroup } from "./types/group.type";

const GroupCreate = () => {
  const navigate = useNavigate();
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [form] = Form.useForm();
  const {
    fetchRegion,
    provinces,
    districts,
    cities,
    activeProvince,
    activeCity,
    searchAddress,
    setActiveCity,
    setActiveProvince,
  } = useAddress();

  async function createGroup(values: ICreateGroup) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        nickname: values.nickname,
        phoneNumber: values.phoneNumber,
        address: {
          address: values.address,
          provinceId: values.province,
          cityId: values.city,
          districtId: values.district,
        },
        fax: values.fax,
        status: values.status ? "active" : "inactive",
      };
      console.log(formData);

      await httpRequest.post("/group", formData);

      message.success(`Success add ${values.name}`);
      form.resetFields();
      navigate("/group");
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    console.log(values);
    createGroup(values);
  };

  useEffect(() => {
    fetchRegion("provinces");
  }, []);

  useEffect(() => {
    if (form.getFieldValue("province")) fetchRegion("cities");
    if (form.getFieldValue("city")) fetchRegion("districts");
  }, [activeProvince, activeCity]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Add Group"}
        subtitle={"Create new group"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="General infromation about this group"
          >
            <CustomFormItemPer1
              label="Group Name"
              name="name"
              rules={generateFormRules("Group Name", ["required"])}
            >
              <Input placeholder="Input group name" />
            </CustomFormItemPer1>
            <Row>
              <CustomFormItemPer3
                label="Group Nickname"
                name="nickname"
                rules={generateFormRules("Group Nickname", ["required"])}
              >
                <Input placeholder="Input group nickname" />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="Phone Number"
                name="phoneNumber"
                rules={generateFormRules("Group Phone Number", ["phoneNumber"])}
              >
                <Input placeholder="Input group phone number contact" />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="Fax"
                name="fax"
                rules={generateFormRules("Fax", ["numeric"])}
              >
                <Input placeholder="Input group fax contact" />
              </CustomFormItemPer3>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle="Address"
            subTitle="Information about address of this group"
          >
            <CustomFormItemPer1
              label="Address"
              name="address"
              rules={generateFormRules("Address", ["required"])}
            >
              <Input placeholder="Input address" />
            </CustomFormItemPer1>
            <Row>
              <CustomFormItemPer3
                label="Province"
                name="province"
                rules={generateFormRules("Province", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Search province"
                  style={{ width: "100%" }}
                  onSearch={(value) => searchAddress("provinces", value)}
                  onChange={(value) => {
                    setActiveProvince(value);
                    form.setFieldsValue({
                      city: "",
                      district: "",
                    });
                  }}
                  allowClear
                  onClear={() => searchAddress("provinces", "")}
                  filterOption={false}
                  options={provinces.map((item) => ({
                    value: item.provinceId,
                    label: item.provinceName,
                  }))}
                />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="City"
                name="city"
                rules={generateFormRules("City", ["required"])}
              >
                <Select
                  showSearch
                  disabled={form.getFieldValue("province") ? false : true}
                  placeholder="Search cities"
                  style={{ width: "100%" }}
                  onSearch={(value) => searchAddress("cities", value)}
                  onChange={(value) => {
                    setActiveCity(value);
                    form.setFieldsValue({
                      district: "",
                    });
                  }}
                  allowClear
                  onClear={() => searchAddress("cities", "")}
                  filterOption={false}
                  options={cities.map((item) => ({
                    value: item.cityId,
                    label: item.cityName,
                  }))}
                />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="District"
                name="district"
                rules={generateFormRules("District", ["required"])}
              >
                <Select
                  showSearch
                  disabled={form.getFieldValue("city") ? false : true}
                  placeholder="Search district"
                  style={{ width: "100%" }}
                  onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => setActiveCity(value)}
                  allowClear
                  onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={districts.map((item) => ({
                    value: item.districtId,
                    label: item.districtName,
                  }))}
                />
              </CustomFormItemPer3>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="Setting about this group"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default GroupCreate;
