import { useDebounce } from "use-debounce";
import { httpRequest } from "../helpers/api";
import React from "react";
import { IHttpResponse, IPayloadPagination } from "@qlibs/react-components";

export type Province = {
  countryId: number;
  provinceId: number;
  provinceName: string;
  createdAt: Date;
  updatedAt: Date;
};

export type City = {
  provinceId: number;
  cityId: number;
  cityName: string;
  createdAt: Date;
  updatedAt: Date;
};

export type District = {
  cityId: number;
  districtId: number;
  districtName: string;
  createdAt: Date;
  updatedAt: Date;
};

export default function useAddress() {
  const [provinces, setProvinces] = React.useState<Province[]>([]);
  const [activeProvince, setActiveProvince] = React.useState<number>();
  const [cities, setCities] = React.useState<City[]>([]);
  const [activeCity, setActiveCity] = React.useState<number>();
  const [districts, setDistricts] = React.useState<District[]>([]);
  const [search, setSearch] = React.useState<string>();
  const [searchValue] = useDebounce(search, 500);
  const [url, setUrl] = React.useState("provinces");

  async function fetchRegion(url: any, search?: string) {
    try {
      // console.log("url", url);
      // console.log("activeProvince", activeProvince);
      // console.log("activeCity", activeCity);
      const response = await httpRequest.get<
        IHttpResponse<IPayloadPagination<any>>
      >("/region-v2/" + url, {
        params: {
          search,
          filterCountryId: 105,
          filterProvinceId: url === "cities" ? activeProvince : null,
          filterCityId: url === "districts" ? activeCity : null,
        },
      });
      if (response.data && response.data.payload) {
        const data = response.data.payload.results;
        if (url === "provinces") {
          setProvinces(data);
        } else if (url === "cities") {
          setCities(data);
        } else {
          setDistricts(data);
        }
      } else {
        setProvinces([]);
      }
    } catch (error) {
      resetData();
      //
    }
  }

  function resetData() {
    setProvinces([]);
    setCities([]);
    setDistricts([]);
  }

  function searchAddress(
    url: "provinces" | "cities" | "districts",
    value?: string
  ) {
    if (value) {
      setSearch(value);
      setUrl(url);
      if (url === "provinces") {
        setCities([]);
        setDistricts([]);
        setActiveCity(undefined);
      }
    } else {
      resetData();
    }
  }

  React.useEffect(() => {
    if (searchValue && url) {
      fetchRegion(url, searchValue);
    }
  }, [searchValue, url]);

  return {
    fetchRegion,
    setSearch,
    provinces,
    cities,
    districts,
    activeProvince,
    activeCity,
    searchAddress,
    setActiveProvince,
    setActiveCity,
  };
}
