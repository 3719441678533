import { Card, Image, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { ReactComponent as IconGSMActive } from "../assets/icons/icon-gsm-active.svg";
import { ReactComponent as IconGSMInActive } from "../assets/icons/icon-gsm-inactive.svg";
import { ReactComponent as IconSatelliteActive } from "../assets/icons/icon-satellite-active.svg";
import { ReactComponent as IconSatelliteInActive } from "../assets/icons/icon-satellite-inactive.svg";
import { ReactComponent as IconInfo } from "../assets/icons/icon-info.svg";
import { ReactComponent as IconFMSApp } from "../assets/icons/icon-app-fms.svg";
import { ReactComponent as IconAM } from "../assets/icons/icon-app-am.svg";
import { ReactComponent as IconTLM } from "../assets/icons/icon-app-tlm.svg";
import { ReactComponent as IconBM } from "../assets/icons/icon-app-bm.svg";
import IconAsset from "../assets/icons/icon-asset.svg";
import IconBunkering from "../assets/icons/icon-bunkering.svg";
import IconFMS from "../assets/icons/icon-fms.svg";
import IconTankLevel from "../assets/icons/icon-tank-level.svg";
import { DownOutlined } from "@ant-design/icons";
import useGroup from "../zustand/useGroup";

const ActionApplication = () => {
  const { company, feature, dataSource, setDataSource, setFeature } =
    useGroup();
  const [isModalSelectApp, setIsModalSelectApp] = useState<boolean>(false);
  const [selectedFeature, setSelectedFeature] = useState<string>(feature);

  const _renderSetFeature = (icon: any, title: string, description: string) => {
    let isActive = false;

    switch (title) {
      case "Fuel Management System":
        isActive = company?.isFms ? company?.isFms : false;
        break;
      case "Asset Management":
        isActive = company?.isAm ? company?.isAm : false;
        break;
      case "Bunkering Management":
        isActive = company?.isBm ? company?.isBm : false;
        break;
      case "Tank Level Management":
        isActive = company?.isTlm ? company?.isTlm : false;
        break;
    }

    return (
      <Card
        style={{
          height: 200,
          cursor: isActive ? "pointer" : "not-allowed",
          border:
            title === selectedFeature
              ? `1px solid #264284`
              : "1px solid rgba(128, 128, 128, 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: isActive
            ? "transparent"
            : "rgba(128, 128, 128, 0.3)",
        }}
        bordered
        onClick={() => {
          if (isActive) setSelectedFeature(title);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Image
            src={icon}
            alt="FMS Icon"
            width={76}
            height={76}
            style={{ objectFit: "contain" }}
            preview={false}
          />
          <div style={{ fontWeight: "bold", marginTop: 10 }}>{title}</div>
          <div style={{ fontSize: 10, marginTop: 5 }}>{description}</div>
        </div>
      </Card>
    );
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: 14 }}>
        <div
          style={{
            marginTop: 12,
            cursor: "pointer",
          }}
        >
          <Tooltip title="Choose data source. You can switch data source of this page between Satellite or GSM">
            <IconInfo />
          </Tooltip>
        </div>
        <div
          style={{
            backgroundColor: "#D2D8E1",
            borderRadius: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
            height: 35,
            gap: 5,
            marginRight: -10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
              cursor: "pointer",
              backgroundColor: dataSource === "GSM" ? "#264284" : "transparent",
              color: dataSource === "GSM" ? "white" : "black",
              height: 30,
              borderRadius: 20,
              paddingLeft: 10,
              paddingRight: 10,
            }}
            onClick={() => setDataSource("GSM")}
          >
            {dataSource === "GSM" ? <IconGSMActive /> : <IconGSMInActive />}
            GSM
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
              cursor: "pointer",
              backgroundColor:
                dataSource === "SATELLITE" ? "#FF842C" : "transparent",
              color: dataSource === "SATELLITE" ? "white" : "black",
              height: 30,
              borderRadius: 20,
              paddingLeft: 10,
              paddingRight: 10,
            }}
            onClick={() => setDataSource("SATELLITE")}
          >
            {dataSource === "SATELLITE" ? (
              <IconSatelliteActive />
            ) : (
              <IconSatelliteInActive />
            )}
            Satellite
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            gap: 5,
            marginLeft: 10,
            marginRight: 10,
          }}
          onClick={() => {
            setIsModalSelectApp(true);
          }}
        >
          {feature === "Fuel Management System" && <IconFMSApp />}
          {feature === "Asset Management" && <IconAM />}
          {feature === "Bunkering Management" && <IconBM />}
          {feature === "Tank Level Management" && <IconTLM />}
          {feature}
          <DownOutlined />
        </div>
      </div>

      <Modal
        open={isModalSelectApp}
        onOk={() => {
          setFeature(selectedFeature);
          setIsModalSelectApp(false);
        }}
        onCancel={() => setIsModalSelectApp(false)}
        width={"45%"}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            padding: "15px 25px 15px 25px",
            backgroundColor: "rgb(232, 236, 243)",
            height: 75,
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 14 }}>
            Select Application
          </div>
          <div style={{ color: "#768499" }}>
            Select application that you want to use
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 20,
            flexWrap: "wrap",
            paddingTop: 20,
            marginTop: 50,
          }}
        >
          {_renderSetFeature(
            IconFMS,
            "Fuel Management System",
            "Select Group and Company that you want to trace"
          )}
          {_renderSetFeature(
            IconAsset,
            "Asset Management",
            "Select Group and Company that you want to trace"
          )}
          {_renderSetFeature(
            IconBunkering,
            "Bunkering Management",
            "Select Group and Company that you want to trace"
          )}
          {_renderSetFeature(
            IconTankLevel,
            "Tank Level Management",
            "Select Group and Company that you want to trace"
          )}
        </div>
      </Modal>
    </>
  );
};

export default ActionApplication;
