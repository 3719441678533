import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Upload,
  message,
} from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
  CustomFormItemPer3,
} from "../user/components/CustomFormItem";
import useAddress from "../../hooks/useAddress";
import { httpRequest, httpRequestMultipart } from "../../helpers/api";
import { CompanyProperties, ICreateCompany } from "./types/company.type";
import SectionContent from "../../components/SectionContent";
import { GroupProperties } from "../group/types/group.type";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";

const getLatestFile = (e: any) => {
  return [e?.fileList.pop()];
};

const allowedMimes = ["image/jpeg", "image/png"];

const CompanyCreate = () => {
  const navigate = useNavigate();
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [form] = Form.useForm();
  const {
    fetchRegion,
    provinces,
    districts,
    cities,
    activeProvince,
    activeCity,
    searchAddress,
    setActiveCity,
    setActiveProvince,
  } = useAddress();
  const [groups, setGroups] = React.useState<GroupProperties[]>([]);

  async function uploadImage(companyId: string, file: File) {
    try {
      let formData = new FormData();
      formData.append("image", file);
      console.log(file);
      console.log(formData);
      await httpRequestMultipart.post(
        "/company/" + companyId + "/upload-flag",
        formData
      );
      // message.success(`Success upload company flag`);
    } catch (error) {
      message.error(`Failed upload company flag`);
      throw error;
    } finally {
      setIsLoadingAction(false);
    }
  }

  async function createCompany(values: ICreateCompany) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        groupId: values.groupId,
        phoneNumber: values.phoneNumber,
        address: {
          address: values.address,
          provinceId: values.province,
          cityId: values.city,
          districtId: values.district,
        },
        picName: values.picName,
        picPhoneNumber: values.picPhoneNumber,
        status: values.status ? "active" : "inactive",
        isFms: values.isFms,
        isBm: values.isBm,
        isAm: values.isAm,
        isTlm: values.isTlm,
      };

      console.log(formData);
      // return;

      const response = await httpRequest.post<
        BaseResponseProps<CompanyProperties>
      >("/company", formData);

      if (response.data.payload && values?.image) {
        await uploadImage(
          response.data.payload.companyId,
          (values?.image[0] as any).originFileObj
        );
      }

      message.success(`Success add ${values.name}`);
      form.resetFields();
      navigate("/company");
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    console.log(values);
    createCompany(values);
  };

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        setIsLoadingAction(true);
        const response = await httpRequest.get<
          BaseResponsePaginationProps<GroupProperties>
        >("/group");

        if (response.data.payload) {
          setGroups(response.data.payload.results);
        }
      } catch (error) {
        message.error(`Failed get data groups`);
      } finally {
        setIsLoadingAction(false);
      }
    };

    fetchGroup();
  }, []);

  const groupMapped = React.useMemo(() => {
    return groups.map((item) => ({
      value: item.groupId,
      label: `(${item.nickname}) ${item.name}`,
    }));
  }, [groups]);

  function handleBeforeUpload(file: RcFile) {
    let max = 2;
    const isImageAllowed = ["image/jpeg", "image/jpg", "image/png"].includes(
      file.type
    );
    const isLimitSize = file.size / 1024 / 1024 < max;

    if (!isImageAllowed) {
      message.error("You can only upload JPG, JPEG, and PNG file!");
    }

    if (!isLimitSize) {
      message.error(`Image must smaller than ${max}MB!`);
    }

    return false;
  }

  useEffect(() => {
    fetchRegion("provinces");
  }, []);

  useEffect(() => {
    if (form.getFieldValue("province")) fetchRegion("cities");
    if (form.getFieldValue("city")) fetchRegion("districts");
  }, [activeProvince, activeCity]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Add Company"}
        subtitle={"Create new company"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about company"
          >
            <CustomFormItemPer1
              label="Company Flag"
              name="image"
              valuePropName="fileList"
              getValueFromEvent={getLatestFile}
            >
              <Upload
                maxCount={1}
                accept={allowedMimes.join(",")}
                beforeUpload={handleBeforeUpload}
                multiple={false}
                action={undefined}
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </CustomFormItemPer1>
            <CustomFormItemPer1
              label="Company Name"
              name="name"
              rules={generateFormRules("Company Name", ["required"])}
            >
              <Input placeholder="Input company name" />
            </CustomFormItemPer1>
            <Row>
              <CustomFormItemPer2
                label="Group"
                name="groupId"
                rules={generateFormRules("Group", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Choose group"
                  style={{ width: "100%" }}
                  options={groupMapped}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="Company Phone Number"
                name="phoneNumber"
                rules={generateFormRules("Company Phone Number", [
                  "phoneNumber",
                ])}
              >
                <Input placeholder="Input company phone number" />
              </CustomFormItemPer2>
            </Row>
            <Row>
              <CustomFormItemPer2
                label="PIC Name"
                name="picName"
                rules={generateFormRules("PIC name", ["letter-and-space"])}
              >
                <Input placeholder="Input company pic name" />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="PIC Phone Number"
                name="picPhoneNumber"
                rules={generateFormRules("PIC phone number", ["phoneNumber"])}
              >
                <Input placeholder="Input company pic phone number" />
              </CustomFormItemPer2>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle="Address"
            subTitle="These are company information, you can change anything"
          >
            <CustomFormItemPer1
              label="Address"
              name="address"
              rules={generateFormRules("Address", ["required"])}
            >
              <Input placeholder="Input address" />
            </CustomFormItemPer1>
            <Row>
              <CustomFormItemPer3
                label="Province"
                name="province"
                rules={generateFormRules("Province", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Search province"
                  style={{ width: "100%" }}
                  onSearch={(value) => searchAddress("provinces", value)}
                  onChange={(value) => {
                    setActiveProvince(value);
                    form.setFieldsValue({
                      city: "",
                      district: "",
                    });
                  }}
                  allowClear
                  onClear={() => searchAddress("provinces", "")}
                  filterOption={false}
                  options={provinces.map((item) => ({
                    value: item.provinceId,
                    label: item.provinceName,
                  }))}
                />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="City"
                name="city"
                rules={generateFormRules("City", ["required"])}
              >
                <Select
                  showSearch
                  disabled={form.getFieldValue("province") ? false : true}
                  placeholder="Search cities"
                  style={{ width: "100%" }}
                  onSearch={(value) => searchAddress("cities", value)}
                  onChange={(value) => {
                    setActiveCity(value);
                    form.setFieldsValue({
                      district: "",
                    });
                  }}
                  allowClear
                  onClear={() => searchAddress("cities", "")}
                  filterOption={false}
                  options={cities.map((item) => ({
                    value: item.cityId,
                    label: item.cityName,
                  }))}
                />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="District"
                name="district"
                rules={generateFormRules("District", ["required"])}
              >
                <Select
                  showSearch
                  disabled={form.getFieldValue("city") ? false : true}
                  placeholder="Search district"
                  style={{ width: "100%" }}
                  onSearch={(value) => searchAddress("districts", value)}
                  onChange={(value) => setActiveCity(value)}
                  allowClear
                  onClear={() => searchAddress("districts", "")}
                  filterOption={false}
                  options={districts.map((item) => ({
                    value: item.districtId,
                    label: item.districtName,
                  }))}
                />
              </CustomFormItemPer3>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Features`}
            subTitle="These are feature information, you can change anything"
          >
            <Row>
              <Col span={6}>
                <CustomFormItemPer1
                  label="Fuel Monitoring System"
                  name="isFms"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch className="custom-switch" />
                </CustomFormItemPer1>
              </Col>
              <Col span={6}>
                <CustomFormItemPer1
                  label="Bunkering Monitoring"
                  name="isBm"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch className="custom-switch" />
                </CustomFormItemPer1>
              </Col>
              <Col span={6}>
                <CustomFormItemPer1
                  label="Asset Monitoring"
                  name="isAm"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch className="custom-switch" />
                </CustomFormItemPer1>
              </Col>
              <Col span={6}>
                <CustomFormItemPer1
                  label="Tank Level Monitoring"
                  name="isTlm"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch className="custom-switch" />
                </CustomFormItemPer1>
              </Col>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="These are company information, you can change anything"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default CompanyCreate;
