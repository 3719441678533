import React, { Suspense, useState } from "react";
import {
  Button,
  Divider,
  Layout,
  Modal,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import {
  DownOutlined,
  LeftCircleOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import UserAvatar from "../../components/UserAvatar";
import useConfigApp from "../../hooks/useConfigApp";
import AppLogo from "../../components/AppLogo";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sidebar from "../../components/Sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../assets/theme";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { httpRequest } from "../../helpers/api";
import {
  MainCheckHealth,
  IdleHandler,
  thisYear,
} from "@qlibs/react-components";
import { MENUS_FLAT } from "../../const/menu";
import config, { APP_VERSION } from "../../const/config";
import { isMobile } from "react-device-detect";
import useGroup from "../../zustand/useGroup";
import ActionApplication from "../../components/ActionApplication";

const { Header, Sider, Content } = Layout;

const AppLayout = () => {
  const navigate = useNavigate();
  const { sidebarCollapsed, toggleSidebar } = useConfigApp();
  const location = useLocation();
  const auth = useAuthUser();
  const signOut = useSignOut();
  const [sidebarShow, setSidebarShow] = useState(isMobile ? false : true);

  const { feature } = useGroup();

  return (
    <>
      <div>
        {/* {!!process.env.REACT_APP_CHECK_HEALTH_URL && (
          <MainCheckHealth
            url={process.env.REACT_APP_CHECK_HEALTH_URL || '/'}
            axiosInstance={httpRequest as any}
          />
        )} */}
        {/* <IdleHandler
          pingUrl={process.env.REACT_APP_CHECK_HEALTH_URL || '/'}
          axiosInstance={httpRequest as any}
          isLoggedIn={auth() ? true : false}
          idleTimeout={undefined}
          sessionTimeout={undefined}
          callbackOnError={() => {
            signOut();
            navigate('/login');
          }}
        >
          {' '}
        </IdleHandler> */}
      </div>
      <Layout style={{ height: "100vh", flexDirection: "row" }}>
        {sidebarShow && (
          <AppSider
            onCollapse={toggleSidebar}
            trigger={null}
            collapsible
            collapsed={sidebarCollapsed}
            collapsedWidth={70}
            width={240}
            style={{
              paddingLeft: 0,
              height: "100vh",
            }}
          >
            <AppLogo collapsed={sidebarCollapsed} />
            <Sidebar collapsed={sidebarCollapsed} />
          </AppSider>
        )}
        {sidebarShow && sidebarCollapsed ? (
          <Tooltip placement="right" title={"Expand Sidebar"}>
            <Button
              style={{
                position: "absolute",
                bottom: 0,
                height: 40,
                width: 70,
                backgroundColor: theme.colors.secondary,
              }}
              onClick={toggleSidebar}
              type="text"
              icon={
                <RightCircleOutlined
                  style={{ color: theme.colors.white }}
                  className="icon-collapsed"
                />
              }
            />
          </Tooltip>
        ) : sidebarShow ? (
          <MinimizeButton
            onClick={() => {
              if (!isMobile) {
                toggleSidebar();
              }
            }}
            style={{
              position: "absolute",
              bottom: 0,
              height: 40,
              width: 240,
              backgroundColor: theme.colors.secondary,
            }}
          >
            <div>
              v{APP_VERSION} · ©{thisYear}
            </div>
            {!isMobile && <LeftCircleOutlined color={theme.colors.white} />}
          </MinimizeButton>
        ) : (
          false
        )}

        <Layout
          id="scroll-content"
          className="site-layout"
          style={{ minHeight: "100vh", overflowY: "scroll" }}
        >
          <AppHeader>
            {isMobile && sidebarShow ? (
              <span
                style={{ cursor: "pointer", padding: 5 }}
                onClick={() => setSidebarShow(false)}
              >
                <MenuFoldOutlined style={{ fontSize: 20 }} />
              </span>
            ) : isMobile ? (
              <span
                style={{ cursor: "pointer", padding: 5 }}
                onClick={() => setSidebarShow(true)}
              >
                <MenuOutlined style={{ fontSize: 20 }} />
              </span>
            ) : (
              false
            )}
            {config.pageTitlePosition === "top-nav" &&
              !isMobile &&
              MENUS_FLAT.map((item: any) => {
                if (item.key === location.pathname) {
                  return (
                    <>
                      <AppHeaderLabel>
                        {item.icon}
                        <Typography.Title
                          level={3}
                          style={{ marginBottom: 0, fontSize: 18 }}
                        >
                          {item.label}
                        </Typography.Title>
                        <Divider type="vertical" />
                        <Typography.Text style={{ color: "darkgrey" }}>
                          {item.description}
                        </Typography.Text>
                      </AppHeaderLabel>
                    </>
                  );
                } else {
                  return false;
                }
              })}

            {config.pageTitlePosition === "content" && !isMobile && (
              <Breadcrumbs />
            )}

            <AppHeaderAccount>
              {config.topNav.showWelcomeName && (
                <DateNow>
                  Welcome, <b>{auth()?.firstName || auth()?.name}</b>
                  <small>
                    (<i>{auth()?.role.roleName}</i>)
                  </small>
                </DateNow>
              )}
              {!isMobile && <DividerTitle />}
              <ActionApplication />
              <UserAvatar />
            </AppHeaderAccount>
          </AppHeader>

          <AppContent>
            <Space size="middle" direction="vertical">
              {config.pageTitlePosition === "top-nav" && (
                <Breadcrumbs marginTop={10} />
              )}

              <Suspense fallback={<Spin spinning={true} />}>
                <Outlet />
              </Suspense>
            </Space>

            <div style={{ height: 100 }}></div>
          </AppContent>
        </Layout>

        {/* <TawkMessengerReact
          propertyId="65a62d590ff6374032c0c3ea"
          widgetId="1hk8i29gf"
        /> */}
      </Layout>
    </>
  );
};

const AppContent = styled(Content)`
  height: 100%;
  padding: 0px 20px 20px 20px;
`;

const AppSider = styled(Sider)`
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: auto;
  overflow-x: hidden;
  padding-left: 10px;
`;

const AppHeader = styled(Header)`
  background-color: #ffffff;
  position: sticky;
  top: 0;
  padding: 0 20px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
`;

const DateNow = styled.div`
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
`;

const AppHeaderAccount = styled.div`
  flex: 1;
  display: grid;
  gap: 8px;
  grid-template-columns: ${isMobile ? undefined : "1fr auto auto"};
  align-items: center;
  justify-content: flex-end;
`;

const AppHeaderLabel = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DividerTitle = styled.div`
  width: 2.5px;
  height: 100%;
  background-color: #eef0f6;
  opacity: 0.4;
  margin: 0 10px;
`;

const MinimizeButton = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  cursor: pointer;

  div {
    color: ${({ theme }) => theme.colors.white};
    font-size: 10px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default AppLayout;
