import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Select,
  Switch,
  Upload,
  UploadFile,
  message,
} from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
  CustomFormItemPer3,
} from "../user/components/CustomFormItem";
import useAddress from "../../hooks/useAddress";
import { httpRequest, httpRequestMultipart } from "../../helpers/api";
import {
  AssetProperties,
  EAssetCategory,
  EAssetType,
  ICreateAsset,
} from "./types/asset.type";
import SectionContent from "../../components/SectionContent";
import { CompanyProperties } from "../company/types/company.type";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import dayjs from "dayjs";

const moment = require("moment");

const getLatestFile = (e: any) => {
  return [e?.fileList.pop()];
};

const allowedMimes = ["image/jpeg", "image/png"];
interface ILocation {
  assetId: string;
}

const AssetEdit = () => {
  const navigate = useNavigate();
  const { assetId } = useParams<keyof ILocation>() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [data, setData] = React.useState<AssetProperties>();
  const [installationAt, setInstallationAt] = React.useState<any>(new Date());
  const [previewImage, setPreviewImage] = React.useState<
    string | ArrayBuffer | null
  >(null);
  const [form] = Form.useForm();
  const {
    provinces,
    districts,
    cities,
    searchAddress,
    setActiveCity,
    setActiveProvince,
  } = useAddress();
  const [companys, setCompanys] = React.useState<CompanyProperties[]>([]);

  async function uploadImage(assetId: string, file: File) {
    try {
      let formData = new FormData();
      formData.append("image", file);
      await httpRequestMultipart.post(
        "/asset/" + assetId + "/upload-image",
        formData
      );
      // message.success(`Success upload asset image`);
    } catch (error) {
      message.error(`Failed upload asset image`);
      throw error;
    } finally {
      setIsLoadingAction(false);
    }
  }

  async function updateAsset(values: ICreateAsset) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        companyId: values.companyId,
        assetType: values.assetType,
        assetCategory: values.assetCategory,
        picName: values.picName,
        picPhoneNumber: values.picPhoneNumber,
        gsmNumber: values.gsmNumber,
        imeiNumber: values.imeiNumber,
        satelliteId: values.satelliteId,
        satelliteDeviceId: values.satelliteDeviceId,
        satelliteOperator: values.satelliteOperator,
        installationAt: dayjs(values.installationAt),
        status: values.status ? "active" : "inactive",
      };

      const response = await httpRequest.patch<
        BaseResponseProps<AssetProperties>
      >("/asset/" + assetId, formData);

      if (response.data.payload && values?.image) {
        await uploadImage(
          response.data.payload.assetId,
          (values?.image[0] as any).originFileObj
        );
      }

      message.success(`Success add ${values.name}`);
      form.resetFields();
      setPreviewImage(null);
      setData(undefined);
      navigate("/asset");
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning("This data has been archived and cannot be edited");
    } else {
      updateAsset(values);
    }
  };

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        setIsLoadingAction(true);
        const response = await httpRequest.get<
          BaseResponsePaginationProps<CompanyProperties>
        >("/company");

        if (response.data.payload) {
          setCompanys(response.data.payload.results);
        }
      } catch (error) {
        message.error(`Failed get data companys`);
      } finally {
        setIsLoadingAction(false);
      }
    };

    fetchCompany();
  }, []);

  const companyMapped = React.useMemo(() => {
    return companys.map((item) => ({
      value: item.companyId,
      label: item.name,
    }));
  }, [companys]);

  const assetTypeMapped = React.useMemo(() => {
    return Object.values(EAssetType).map((item) => ({
      value: item,
      label: item,
    }));
  }, []);

  const assetCategoryMapped = React.useMemo(() => {
    return Object.values(EAssetCategory).map((item) => ({
      value: item,
      label: item,
    }));
  }, []);

  function handleImagePreview(e: any) {
    console.log(e);
    const file = e.file;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  function handleBeforeUpload(file: RcFile) {
    let max = 2;
    const isImageAllowed = ["image/jpeg", "image/jpg", "image/png"].includes(
      file.type
    );
    const isLimitSize = file.size / 1024 / 1024 < max;

    if (!isImageAllowed) {
      message.error("You can only upload JPG, JPEG, and PNG file!");
    }

    if (!isLimitSize) {
      message.error(`Image must smaller than ${max}MB!`);
    }

    return false;
  }

  async function fetchData(assetId: string) {
    try {
      const response = await httpRequest.get<
        BaseResponseProps<AssetProperties>
      >("/asset/" + assetId);
      if (response.data.payload) {
        const data = response.data.payload;

        setData(data);

        if (data.imageLink) {
          setPreviewImage(data.imageLink);
        }

        setInstallationAt(dayjs(data.installationAt));

        const formData = {
          ...data,
          installationAt: dayjs(data.installationAt),
          status: data.status === "active" ? true : false,
        };
        form.setFieldsValue(formData);
      }
    } catch (error) {
      message.error(`Failed get asset data`);
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  useEffect(() => {
    if (assetId) {
      fetchData(assetId);
    }
  }, [assetId]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Update Asset"}
        subtitle={"Update current asset"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        {data?.deletedAt ? (
          <Alert
            className="mb-5"
            type="warning"
            message="This data has been archived and cannot be edited"
          />
        ) : (
          false
        )}
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about asset"
          >
            <CustomFormItemPer1
              label="Image"
              name="imagePreview"
              valuePropName="fileList"
              getValueFromEvent={getLatestFile}
              style={{ marginBottom: 0 }}
            >
              <Image
                fallback="/images/empty-image.png"
                src={previewImage as any}
                width={"100%"}
                height={300}
                style={{
                  objectFit: "contain",
                  backgroundColor: "#f4f6f9",
                  borderRadius: 8,
                  marginBottom: 40,
                  display: "block",
                }}
              />
            </CustomFormItemPer1>
            <CustomFormItemPer1
              name="image"
              valuePropName="fileList"
              getValueFromEvent={getLatestFile}
            >
              <Upload
                maxCount={1}
                accept={allowedMimes.join(",")}
                beforeUpload={handleBeforeUpload}
                multiple={false}
                showUploadList={false}
                action={undefined}
                name="image"
                onChange={(e) => {
                  handleImagePreview(e);
                }}
                className="upload-form"
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
                <span className="upload-info">
                  Allowed type are JPEG, JPG and PNG. Max size are 2 Mb.
                  Recommended size are 1000x1000 pixel
                </span>
              </Upload>
            </CustomFormItemPer1>
            <CustomFormItemPer1
              label="Asset Name"
              name="name"
              rules={generateFormRules("Asset Name", ["required"])}
            >
              <Input placeholder="Input asset name" />
            </CustomFormItemPer1>
            <CustomFormItemPer1
              label="Company"
              name="companyId"
              rules={generateFormRules("Company", ["required"])}
            >
              <Select
                showSearch
                placeholder="Choose company"
                style={{ width: "100%" }}
                options={companyMapped}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </CustomFormItemPer1>
            <Row>
              <CustomFormItemPer2
                label="Asset Type"
                name="assetType"
                rules={generateFormRules("Asset Type", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Choose asset type"
                  style={{ width: "100%" }}
                  options={assetTypeMapped}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="Asset Category"
                name="assetCategory"
                rules={generateFormRules("Asset Category", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Choose asset category"
                  style={{ width: "100%" }}
                  options={assetCategoryMapped}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer2>
            </Row>
            <Row>
              <CustomFormItemPer2
                label="PIC Name"
                name="picName"
                rules={generateFormRules("PIC name", ["letter-and-space"])}
              >
                <Input placeholder="Input pic name" />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="PIC Phone Number"
                name="picPhoneNumber"
                rules={generateFormRules("PIC phone number", ["phoneNumber"])}
              >
                <Input placeholder="Input pic phone number" />
              </CustomFormItemPer2>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Satellite & ID`}
            subTitle="These are asset information, you can change anything"
          >
            <Row>
              <CustomFormItemPer2
                label="GSM Number"
                name="gsmNumber"
                rules={generateFormRules("GSM Number", ["numeric"])}
              >
                <Input placeholder="Input gsm number" />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="IMEI Number"
                name="imeiNumber"
                rules={generateFormRules("Imei Number", ["numeric"])}
              >
                <Input placeholder="Input imei number" />
              </CustomFormItemPer2>
            </Row>
            <Row>
              <CustomFormItemPer3
                label="Satellite ID"
                name="satelliteId"
                rules={generateFormRules("Satellite ID", ["numeric"])}
              >
                <Input placeholder="Input satellite id" />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="Satellite Device ID"
                name="satelliteDeviceId"
                rules={generateFormRules("Satellite Device ID", ["numeric"])}
              >
                <Input placeholder="Input satellite device id" />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="Satellite Operator"
                name="satelliteOperator"
                rules={generateFormRules("Satellite Operator", ["numeric"])}
              >
                <Input placeholder="Input satellite operator" />
              </CustomFormItemPer3>
            </Row>
            <Row>
              <CustomFormItemPer3
                label="Installation Date"
                name="installationAt"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  value={dayjs(installationAt)}
                  defaultValue={dayjs(installationAt)}
                />
              </CustomFormItemPer3>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="These are asset information, you can change anything"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default AssetEdit;
