import create, { StateCreator } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";
import {
  CompanyProperties,
  initialCompany,
} from "../screens/company/types/company.type";
import {
  GroupProperties,
  initialGroup,
} from "../screens/group/types/group.type";

type CompanyProps = {
  group: GroupProperties;
  company: CompanyProperties;
  feature: string;
  dataSource: "GSM" | "SATELLITE";
  setGroup: (group: GroupProperties) => void;
  setCompany: (company: CompanyProperties) => void;
  setFeature: (value: string) => void;
  setDataSource: (value: "GSM" | "SATELLITE") => void;
};

type MyPersist = (
  config: any,
  options: PersistOptions<CompanyProps>
) => StateCreator<CompanyProps>;

const useGroup = create<CompanyProps>(
  (persist as MyPersist)(
    (set: any) => ({
      group: initialGroup,
      company: initialCompany,
      feature: "",
      dataSource: "GSM",
      setGroup: (group: GroupProperties) => {
        set({ group });
      },
      setCompany: (company: CompanyProperties) => {
        set({ company });
      },
      setFeature: (value: string) => {
        set({ feature: value });
      },
      setDataSource: (value: "GSM" | "SATELLITE") => {
        set({ dataSource: value });
      },
    }),
    {
      name: "group-selected",
      getStorage: () => localStorage,
    }
  )
);

export default useGroup;
