import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import {
  Alert,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
} from "../user/components/CustomFormItem";
import { httpRequest } from "../../helpers/api";
import SectionContent from "../../components/SectionContent";
import { AssetProperties } from "../asset/types/asset.type";
import { DeviceTypeProperties, ICreateDeviceType } from "./types/device.type";

interface ILocation {
  deviceTypeId: string;
}

const DeviceEdit = () => {
  const navigate = useNavigate();
  const { deviceTypeId } = useParams<keyof ILocation>() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [data, setData] = React.useState<DeviceTypeProperties>();
  const [form] = Form.useForm();
  const [assets, setAssets] = React.useState<AssetProperties[]>([]);
  const [deviceTypes, setDeviceTypes] = React.useState<DeviceTypeProperties[]>(
    []
  );

  async function updateDevice(values: ICreateDeviceType) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        status: values.status ? "active" : "inactive",
      };

      await httpRequest.patch<BaseResponseProps<DeviceTypeProperties>>(
        "/device-type/" + deviceTypeId,
        formData
      );

      message.success(`Success add ${values.name}`);
      form.resetFields();
      setData(undefined);
      navigate("/device-type");
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning("This data has been archived and cannot be edited");
    } else {
      updateDevice(values);
    }
  };

  async function fetchData(deviceTypeId: string) {
    try {
      const response = await httpRequest.get<
        BaseResponseProps<DeviceTypeProperties>
      >("/device-type/" + deviceTypeId);
      if (response.data.payload) {
        const data = response.data.payload;

        setData(data);

        const formData = {
          ...data,
          status: data.status === "active" ? true : false,
        };
        form.setFieldsValue(formData);
      }
    } catch (error) {
      message.error(`Failed get device data`);
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  useEffect(() => {
    if (deviceTypeId) {
      fetchData(deviceTypeId);
    }
  }, [deviceTypeId]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Update Device Type"}
        subtitle={"Update current device type"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        {data?.deletedAt ? (
          <Alert
            className="mb-5"
            type="warning"
            message="This data has been archived and cannot be edited"
          />
        ) : (
          false
        )}
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about device type"
          >
            <Row>
              <CustomFormItemPer2
                label="Device Type Name"
                name="name"
                rules={generateFormRules("Device Type Name", ["required"])}
              >
                <Input placeholder="Input device type name" />
              </CustomFormItemPer2>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="These are device information, you can change anything"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default DeviceEdit;
