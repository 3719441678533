import { AssetProperties, initialAsset } from "../../asset/types/asset.type";
import {
  DeviceTypeProperties,
  initialDeviceType,
} from "../../deviceType/types/device.type";

export enum EDeviceType {
  GPS = "gps",
  FLOWMETER = "flowmeter",
  RPM = "rpm",
  AE = "ae",
  GATEWAY = "gateway",
  POWER_SUPPLY = "power_supply",
}

export type DeviceProperties = {
  deviceId: string;
  uniqueId: string;
  name: string;
  status: string;
  deviceTypeId: string;
  description?: string;
  createdByUserId: string;
  userCreator?: {
    name?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
  };
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  assetId: string;
  asset: AssetProperties;
  deviceType: DeviceTypeProperties;
};

export const initialDevice: DeviceProperties = {
  deviceId: "",
  name: "",
  uniqueId: "",
  deviceTypeId: "",
  status: "",
  createdByUserId: "",
  assetId: "",
  asset: { ...initialAsset },
  deviceType: { ...initialDeviceType },
};

export interface ICreateDevice {
  name: string;
  uniqueId: string;
  deviceType: string;
  status: string;
  assetId: string;
  description?: string;
  createdByUserId: string;
}
