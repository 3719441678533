import {
  AddressProperties,
  GroupProperties,
} from "../../group/types/group.type";
import { UserProperties } from "../../user/types/user.type";

export type CompanyProperties = {
  companyId: string;
  name: string;
  address?: AddressProperties;
  phoneNumber?: string;
  picName?: string;
  picPhoneNumber?: string;
  status: string;
  createdByUserId: string;
  groupId: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  group?: GroupProperties;
  users?: UserProperties[];
  assets?: any;
  imageLink?: string;
  userCreator?: {
    name?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
  };
  isFms: boolean;
  isBm: boolean;
  isAm: boolean;
  isTlm: boolean;
};

export const initialCompany: CompanyProperties = {
  companyId: "",
  name: "",
  status: "",
  createdByUserId: "",
  groupId: "",
  isFms: false,
  isBm: false,
  isAm: false,
  isTlm: false,
};

export interface ICreateCompany {
  name: string;
  groupId: string;
  phoneNumber?: string;
  province?: number;
  city?: number;
  district?: number;
  address?: string;
  status: string;
  picName: string;
  picPhoneNumber: string;
  image: File[];
  isFms: boolean;
  isBm: boolean;
  isAm: boolean;
  isTlm: boolean;
}
