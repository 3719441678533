import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Upload,
  message,
} from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
  CustomFormItemPer3,
} from "../user/components/CustomFormItem";
import useAddress from "../../hooks/useAddress";
import { httpRequest, httpRequestMultipart } from "../../helpers/api";
import { DeviceTypeProperties, ICreateDeviceType } from "./types/device.type";
import SectionContent from "../../components/SectionContent";
import { AssetProperties } from "../asset/types/asset.type";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";

const DeviceCreate = () => {
  const navigate = useNavigate();
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [form] = Form.useForm();

  async function createDeviceType(values: ICreateDeviceType) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        status: values.status ? "active" : "inactive",
      };

      console.log(formData);
      // return;

      await httpRequest.post<BaseResponseProps<DeviceTypeProperties>>(
        "/device-type",
        formData
      );

      message.success(`Success add ${values.name}`);
      form.resetFields();
      navigate("/device-type");
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    console.log(values);
    createDeviceType(values);
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Add Device Type"}
        subtitle={"Create new device type"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about device type"
          >
            <Row>
              <CustomFormItemPer2
                label="Device Type Name"
                name="name"
                rules={generateFormRules("Device Type Name", ["required"])}
              >
                <Input placeholder="Input device type name" />
              </CustomFormItemPer2>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="These are device information, you can change anything"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default DeviceCreate;
