import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import {
  Alert,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
} from "../../user/components/CustomFormItem";
import { httpRequest } from "../../../helpers/api";
import SectionContent from "../../../components/SectionContent";
import { AssetProperties } from "../../asset/types/asset.type";
import {
  ICreateOperationModeDetail,
  OperationModeDetailProperties,
} from "../types/operationMode.type";
import dayjs from "dayjs";
import useMasterData from "../../../hooks/useMasterData";
import useDetailBreadcrumbs from "../../../hooks/useDetailBreadcrumbs";

interface ILocation {
  operationModeId: string;
  operationModeDetailId: string;
}

const OperationModeDetailEdit = () => {
  const navigate = useNavigate();
  const { operationModeId, operationModeDetailId } = useParams<
    keyof ILocation
  >() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [data, setData] = React.useState<OperationModeDetailProperties>();
  const [form] = Form.useForm();

  async function updateOperationMode(values: ICreateOperationModeDetail) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        rpmMax: values.rpmMax,
        consumptionRate: values.consumptionRate,
        status: values.status ? "active" : "inactive",
      };

      console.log(formData);
      // return;

      await httpRequest.patch<BaseResponseProps<OperationModeDetailProperties>>(
        "/operation-mode-detail/" + operationModeDetailId,
        formData
      );

      message.success(`Success update operation mode detail`);
      form.resetFields();
      setData(undefined);
      navigate(`/operation-mode/${operationModeId}`);
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning("This data has been archived and cannot be edited");
    } else {
      updateOperationMode(values);
    }
  };

  async function fetchData(operationModeDetailId: string) {
    try {
      const response = await httpRequest.get<
        BaseResponseProps<OperationModeDetailProperties>
      >("/operation-mode-detail/" + operationModeDetailId);
      if (response.data.payload) {
        const data = response.data.payload;

        setData(data);

        const formData = {
          ...data,
          status: data.status === "active" ? true : false,
        };
        form.setFieldsValue(formData);
      }
    } catch (error) {
      message.error(`Failed get operation mode detail data`);
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  useEffect(() => {
    if (operationModeDetailId) {
      fetchData(operationModeDetailId);
    }
  }, [operationModeDetailId]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Update Operation Mode Detail"}
        subtitle={"Update current operation mode detail"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        {data?.deletedAt ? (
          <Alert
            className="mb-5"
            type="warning"
            message="This data has been archived and cannot be edited"
          />
        ) : (
          false
        )}
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about operation mode detail"
          >
            <Row>
              <CustomFormItemPer2
                label="Name"
                name="name"
                rules={generateFormRules("Name", ["required"])}
              >
                <Input placeholder="Input name" />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="RPM Max"
                name="rpmMax"
                rules={generateFormRules("RPM Max", ["numeric", "required"])}
              >
                <Input placeholder="Input rpm max" type="number" />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="Consumption Rate"
                name="consumptionRate"
                rules={generateFormRules("Consumption Rate", ["required"])}
              >
                <Input placeholder="Input consumption rate" />
              </CustomFormItemPer2>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="These are operation mode information, you can change anything"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default OperationModeDetailEdit;
