import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import {
  Alert,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
} from "../user/components/CustomFormItem";
import { httpRequest } from "../../helpers/api";
import {
  DeviceProperties,
  EDeviceType,
  ICreateDevice,
} from "./types/device.type";
import SectionContent from "../../components/SectionContent";
import { AssetProperties } from "../asset/types/asset.type";
import { DeviceTypeProperties } from "../deviceType/types/device.type";

interface ILocation {
  deviceId: string;
}

const DeviceEdit = () => {
  const navigate = useNavigate();
  const { deviceId } = useParams<keyof ILocation>() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [data, setData] = React.useState<DeviceProperties>();
  const [form] = Form.useForm();
  const [assets, setAssets] = React.useState<AssetProperties[]>([]);
  const [deviceTypes, setDeviceTypes] = React.useState<DeviceTypeProperties[]>(
    []
  );

  async function updateDevice(values: ICreateDevice) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        uniqueId: values.uniqueId,
        deviceTypeId: values.deviceType,
        assetId: values.assetId,
        description: values.description,
        createdByUserId: values.createdByUserId,
        status: values.status ? "active" : "inactive",
      };

      await httpRequest.patch<BaseResponseProps<DeviceProperties>>(
        "/device/" + deviceId,
        formData
      );

      message.success(`Success add ${values.name}`);
      form.resetFields();
      setData(undefined);
      navigate("/device");
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning("This data has been archived and cannot be edited");
    } else {
      updateDevice(values);
    }
  };

  async function getDeviceTypes() {
    try {
      const res = await httpRequest.get<
        BaseResponsePaginationProps<DeviceTypeProperties>
      >("/device-type?filterStatus=active");

      if (res) {
        setDeviceTypes(res.data.payload.results);
      }
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  useEffect(() => {
    const fetchAsset = async () => {
      try {
        setIsLoadingAction(true);
        const response = await httpRequest.get<
          BaseResponsePaginationProps<AssetProperties>
        >("/asset");

        if (response.data.payload) {
          setAssets(response.data.payload.results);
        }
      } catch (error) {
        message.error(`Failed get data assets`);
      } finally {
        setIsLoadingAction(false);
      }
    };

    fetchAsset();
    getDeviceTypes();
  }, []);

  const assetMapped = React.useMemo(() => {
    return assets.map((item) => ({
      value: item.assetId,
      label: item.name,
    }));
  }, [assets]);

  const deviceTypeMapped = React.useMemo(() => {
    return Object.values(deviceTypes).map((item) => ({
      label: item.name,
      value: item.deviceTypeId,
    }));
  }, [deviceTypes]);

  async function fetchData(deviceId: string) {
    try {
      const response = await httpRequest.get<
        BaseResponseProps<DeviceProperties>
      >("/device/" + deviceId);
      if (response.data.payload) {
        const data = response.data.payload;

        setData(data);

        const formData = {
          ...data,
          deviceType: data.deviceTypeId,
          status: data.status === "active" ? true : false,
        };
        form.setFieldsValue(formData);
      }
    } catch (error) {
      message.error(`Failed get device data`);
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  useEffect(() => {
    if (deviceId) {
      fetchData(deviceId);
    }
  }, [deviceId]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Update Device"}
        subtitle={"Update current device"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        {data?.deletedAt ? (
          <Alert
            className="mb-5"
            type="warning"
            message="This data has been archived and cannot be edited"
          />
        ) : (
          false
        )}
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about device"
          >
            <Row>
              <CustomFormItemPer2
                label="Device Name"
                name="name"
                rules={generateFormRules("Device Name", ["required"])}
              >
                <Input placeholder="Input device name" />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="Device Unique ID"
                name="uniqueId"
                rules={generateFormRules("Device Unique ID", ["required"])}
              >
                <Input placeholder="Input unique ID" />
              </CustomFormItemPer2>
            </Row>
            <Row>
              <CustomFormItemPer2
                label="Asset"
                name="assetId"
                rules={generateFormRules("Asset", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Choose asset"
                  style={{ width: "100%" }}
                  options={assetMapped}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="Device Type"
                name="deviceType"
                rules={generateFormRules("Device Phone Number", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Choose device type"
                  style={{ width: "100%" }}
                  options={deviceTypeMapped}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer2>
            </Row>
            <CustomFormItemPer1 label="Description" name="description">
              <Input placeholder="Input device description" />
            </CustomFormItemPer1>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="These are device information, you can change anything"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default DeviceEdit;
