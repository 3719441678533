import {
  BaseResponseProps,
  DetailItem,
  HeaderSection,
  IAppTableColumn,
} from "@qlibs/react-components";
import { CompanyProperties, initialCompany } from "./types/company.type";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useNavigate, useParams } from "react-router-dom";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import React from "react";
import { httpRequest } from "../../helpers/api";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import SectionContent from "../../components/SectionContent";
import { generateAddress } from "../../helpers/address";
import { ColumnsType } from "antd/lib/table";
import { CustomFormItemPer1 } from "../user/components/CustomFormItem";

const moment = require("moment");

interface ILocation {
  companyId: string;
}

interface ResponseProps
  extends BaseResponseProps<
    Omit<CompanyProperties, "createdAt" | "updatedAt" | "companies">
  > {}

const getLatestFile = (e: any) => {
  return [e?.fileList.pop()];
};

const CompanyDetail = () => {
  const navigate = useNavigate();
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const { companyId } = useParams<keyof ILocation>() as ILocation;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<CompanyProperties>({
    ...initialCompany,
  });
  const [previewImage, setPreviewImage] = React.useState<
    string | ArrayBuffer | null
  >(null);

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          "/company/" + companyId
        );
        if (!res) {
          message.error("Something went wrong");
          return;
        }

        setData({
          ...res.data.payload,
        });

        if (res.data.payload.imageLink) {
          setPreviewImage(res.data.payload.imageLink);
        }

        let label = `Detail Company`;
        const bcDetails = [
          {
            field: "companyId",
            value: companyId,
            label,
          },
        ];
        setBreadcrumbDetails(bcDetails);

        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchUser();

    // eslint-disable-next-line
  }, [companyId]);

  const handleClickEdit = () => {
    navigate("/company/" + companyId + "/edit");
  };

  const columns: ColumnsType<any> = [
    {
      title: "Image",
      dataIndex: "imageLink",
      key: "imageLink",
      width: 70,
      render: (value: string, _: any) => (
        <Image
          width={60}
          height={60}
          style={{
            objectFit: "contain",
            border: "thin solid #ddd",
            overflow: "hidden",
            background: "#dadada",
            borderRadius: 4,
          }}
          src={value}
          fallback="/images/flag-not-found.png"
        />
      ),
    },
    {
      title: "Asset Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      key: "assetType",
      render: (value: string, _: any) => (
        <DetailItem label="" value={value.toUpperCase()} />
      ),
    },
    {
      title: "Asset Category",
      dataIndex: "assetCategory",
      key: "assetCategory",
      render: (value: string, _: any) => (
        <DetailItem label="" value={value.toUpperCase()} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string, _: any) => (
        <DetailItem
          label=""
          value={value.toUpperCase()}
          type="tag"
          _tagProps={{
            color: value === "active" ? "success" : "error",
          }}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Detail Company"}
        subtitle={`Manage your company data`}
        rightAction={
          <Space>
            <Button
              disabled={data.deletedAt ? true : false}
              type="primary"
              onClick={handleClickEdit}
            >
              Edit Company
            </Button>
          </Space>
        }
      />

      <Spin spinning={isLoading}>
        <Card>
          {data?.deletedAt ? (
            <Alert
              className="mb-5"
              type="warning"
              message="This data has been archived and cannot be edited"
            />
          ) : (
            false
          )}
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about company"
          >
            <Row>
              <Col span={24}>
                <p style={{ color: "rgb(118, 132, 153)" }}>Company Flag</p>
                <Image
                  fallback="/images/empty-image.png"
                  src={previewImage as any}
                  width={"100%"}
                  height={300}
                  style={{
                    objectFit: "contain",
                    backgroundColor: "#f4f6f9",
                    borderRadius: 8,
                    marginBottom: 40,
                    display: "block",
                    marginTop: -5,
                  }}
                />
              </Col>
              <Col span={12}>
                <DetailItem label="Name" value={data.name} />
              </Col>
              <Col span={12}>
                <DetailItem label="Group" value={data.group?.name || "N/A"} />
              </Col>
              <Col span={12}>
                <DetailItem label="PIC Name" value={data.picName || "N/A"} />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="PIC Phone Number"
                  value={data.picPhoneNumber || "N/A"}
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Created by"
                  value={data?.userCreator?.name || "N/A"}
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Created at"
                  value={moment(data.createdAt).format("DD MMMM YYYY, HH:mm")}
                  // type='datetime'
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Updated at"
                  value={moment(data.updatedAt).format("DD MMMM YYYY, HH:mm")}
                  // type='datetime'
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Status"
                  value={data?.status?.toUpperCase()}
                  type="tag"
                  _tagProps={{
                    color: data.status === "active" ? "success" : "error",
                  }}
                />
              </Col>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle="Address"
            subTitle="These are company information, you can change anything"
          >
            <Row>
              <DetailItem
                label="Address"
                value={generateAddress(data.address)}
              />
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle="Features"
            subTitle="These are feature information, you can change anything"
          >
            <Row>
              <Col span={6}>
                <DetailItem
                  label="Fuel Monitoring System"
                  value={data?.isFms ? "Active" : "Inactive"}
                  type="tag"
                  _tagProps={{
                    color: data?.isFms ? "success" : "error",
                  }}
                />
              </Col>
              <Col span={6}>
                <DetailItem
                  label="Bunkering Monitoring"
                  value={data?.isBm ? "Active" : "Inactive"}
                  type="tag"
                  _tagProps={{
                    color: data?.isBm ? "success" : "error",
                  }}
                />
              </Col>
              <Col span={6}>
                <DetailItem
                  label="Asset Monitoring"
                  value={data?.isAm ? "Active" : "Inactive"}
                  type="tag"
                  _tagProps={{
                    color: data?.isAm ? "success" : "error",
                  }}
                />
              </Col>
              <Col span={6}>
                <DetailItem
                  label="Tank Level Monitoring"
                  value={data?.isTlm ? "Active" : "Inactive"}
                  type="tag"
                  _tagProps={{
                    color: data?.isTlm ? "success" : "error",
                  }}
                />
              </Col>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle="Assets"
            subTitle="These are company information, you can change anything"
          >
            <Table
              columns={columns}
              dataSource={data.assets}
              pagination={false}
            />
          </SectionContent>
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default CompanyDetail;
