import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
  CustomFormItemPer3,
} from "../../user/components/CustomFormItem";
import useAddress from "../../../hooks/useAddress";
import { httpRequest, httpRequestMultipart } from "../../../helpers/api";
import SectionContent from "../../../components/SectionContent";
import { AssetProperties } from "../../asset/types/asset.type";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import {
  ICreateOperationModeDetail,
  OperationModeDetailProperties,
} from "../types/operationMode.type";

interface ILocation {
  operationModeId: string;
}

const OperationModeDetailCreate = () => {
  const navigate = useNavigate();
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [form] = Form.useForm();
  const { operationModeId } = useParams<keyof ILocation>() as ILocation;

  async function createOperationModeDetail(values: ICreateOperationModeDetail) {
    try {
      setIsLoadingAction(true);
      const formData = {
        operationModeId,
        name: values.name,
        rpmMax: values.rpmMax,
        consumptionRate: values.consumptionRate,
        status: values.status ? "active" : "inactive",
      };

      console.log(formData);
      // return;

      await httpRequest.post<BaseResponseProps<OperationModeDetailProperties>>(
        "/operation-mode-detail",
        formData
      );

      message.success(`Success add operation mode detail`);
      form.resetFields();
      navigate(`/operation-mode/${operationModeId}`);
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    console.log(values);
    createOperationModeDetail(values);
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Add Operation Mode Detail"}
        subtitle={"Create new operation mode detail"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about operation mode detail"
          >
            <Row>
              <CustomFormItemPer2
                label="Name"
                name="name"
                rules={generateFormRules("Name", ["required"])}
              >
                <Input placeholder="Input name" />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="RPM Max"
                name="rpmMax"
                rules={generateFormRules("RPM Max", ["numeric", "required"])}
              >
                <Input placeholder="Input rpm max" type="number" />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label="Consumption Rate"
                name="consumptionRate"
                rules={generateFormRules("Consumption Rate", ["required"])}
              >
                <Input placeholder="Input consumption rate" />
              </CustomFormItemPer2>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="These are operation mode detail information, you can change anything"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default OperationModeDetailCreate;
