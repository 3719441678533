import { useLocation, useNavigate } from "react-router-dom";
import usePermission from "../../hooks/usePermission";
import React, { useEffect, useState } from "react";
import {
  HeaderSection,
  AppTable,
  useFetchList,
  PAGE_SIZE_OPTIONS,
  IAppTableColumn,
  formatDate,
  BaseResponsePaginationProps,
} from "@qlibs/react-components";
import RowFilter from "@qlibs/react-components/dist/Table/RowFilter";
import { Button, Image, Modal, Table, Tabs, Tag, message } from "antd";
import { httpRequest } from "../../helpers/api";
import { EDateFormat } from "../../const/config";
import { AssetProperties } from "../asset/types/asset.type";
import LinkTable from "../../components/LinkTable";
import {
  ReportEmailReceiverProperties,
  ReportEmailTargetAssetProperties,
  ReportEmailTemplateProperties,
} from "./types/report.type";
import useGroup from "../../zustand/useGroup";
import useMasterData from "../../hooks/useMasterData";

const moment = require("moment");

const FEATURE = "REPORT_EMAIL_RECEIVER";

const MasterReport = () => {
  const navigate = useNavigate();
  const { isUserHasPermission } = usePermission();

  const handleCreate = () => {
    navigate({
      pathname: `/master-report/${
        activeTab === 1
          ? "email-template/add"
          : activeTab === 2
          ? "email-receiver/add"
          : "email-target-by-asset/add"
      }`,
    });
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const [isModalRestoreOpen, setIsModalRestoreOpen] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<any>();
  const [forceDeleteId, setForceDeleteId] = useState<string>();
  const [activeTab, setActiveTab] = useState<any>(1);
  const [expandedRowKey, setExpandedRowKey] = React.useState<any>();

  const { company } = useGroup();
  const { companies, getListCompanies } = useMasterData();

  const {
    isLoading,
    data,
    pagination,
    query,
    setData,
    setQuery,
    changePage,
    changeLimit,
    handleSearch,
    pageQueries,
    filterDropdown,
    fetchList,
    setIsLoading,
  } = useFetchList<any>({
    httpRequest: httpRequest as any,
    endpoint: `${
      activeTab === 1
        ? "template-messages"
        : activeTab === 2
        ? "report-email-receiver"
        : "report-email-target-asset"
    }`,
    limit: +PAGE_SIZE_OPTIONS[1],
    initialQuery: {
      externalId: company?.companyId || "",
      filterCompanyId: company?.companyId || "",
    },
    pageQuery: {
      defaultValue: {
        page: 1,
      },
    },
  });

  async function deleteData() {
    try {
      if (currentData) {
        const forceDelete =
          forceDeleteId &&
          forceDeleteId ===
            currentData[
              `${
                activeTab === 1
                  ? "templateId"
                  : activeTab === 2
                  ? "reportEmailReceiverId"
                  : "reportEmailTargetAssetId"
              }`
            ];

        const url =
          activeTab === 1
            ? "/template-messages/"
            : activeTab === 2
            ? "/report-email-receiver/"
            : "/report-email-target-asset/";

        await httpRequest.delete(
          url +
            currentData[
              `${
                activeTab === 1
                  ? "templateId"
                  : activeTab === 2
                  ? "reportEmailReceiverId"
                  : "reportEmailTargetAssetId"
              }`
            ],
          {
            params: {
              forceDelete: forceDelete || undefined,
            },
          }
        );
        message.success(
          `Success ${forceDelete || activeTab === 1 ? "delete" : "archive"}`
        );

        setQuery({
          limit: +PAGE_SIZE_OPTIONS[1],
          [`${
            activeTab === 1
              ? "externalId"
              : activeTab === 2
              ? "filterCompanyId"
              : "filterCompanyId"
          }`]: company?.companyId || "",
        });
      } else {
        message.error("Current data not found");
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsModalDeleteOpen(false);
      setCurrentData(undefined);
    }
  }

  async function restoreData() {
    try {
      if (currentData) {
        const url =
          activeTab === 1
            ? "/template-messages/"
            : activeTab === 2
            ? "/report-email-receiver/"
            : "/report-email-target-asset/";

        await httpRequest.patch(
          url +
            currentData[
              `${
                activeTab === 1
                  ? "templateId"
                  : activeTab === 2
                  ? "reportEmailReceiverId"
                  : "reportEmailTargetAssetId"
              }`
            ] +
            "/restore"
        );
        message.success(`Success restore`);

        setQuery({
          limit: +PAGE_SIZE_OPTIONS[1],
          [`${
            activeTab === 1
              ? "externalId"
              : activeTab === 2
              ? "filterCompanyId"
              : "filterCompanyId"
          }`]: company?.companyId || "",
        });
      } else {
        message.error("Current data not found");
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsModalRestoreOpen(false);
      setCurrentData(undefined);
      setForceDeleteId(undefined);
    }
  }

  async function handleRestore(id: any) {
    setIsModalRestoreOpen(true);
    const found = data.find((item) => item.reportEmailReceiverId === id);
    if (found) {
      setCurrentData(found);
    }
  }

  async function handleDelete(id: any, forced?: boolean) {
    setIsModalDeleteOpen(true);
    const found = data.find(
      (item) =>
        item[
          `${
            activeTab === 1
              ? "templateId"
              : activeTab === 2
              ? "reportEmailReceiverId"
              : "reportEmailTargetAssetId"
          }`
        ] === id
    );
    if (found) {
      setCurrentData(found);
    }

    if (forced) {
      setForceDeleteId(id);
    }
  }

  function handleCancelDelete() {
    setIsModalDeleteOpen(false);
    setIsModalRestoreOpen(false);
    setCurrentData(undefined);
  }

  function isArchived(id?: string) {
    if (id) {
      const found = data.find(
        (item) => item.reportEmailReceiverId === id && item.deletedAt
      );
      if (found) {
        return true;
      }
    }

    return false;
  }

  const columnsEmail: IAppTableColumn<ReportEmailReceiverProperties>[] = [
    {
      title: "TARGET EMAIL",
      dataIndex: "name",
      key: "reportEmailReceiverId",
      render: (value, records) => (
        <div>
          {records.deletedAt ? (
            <Tag color="#ff5500" className="mb-2">
              Archived
            </Tag>
          ) : (
            false
          )}
          {records?.user?.email}
        </div>
      ),
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
      render: (value, records) => <div>{records?.company?.name}</div>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (value, _) => (
        <Tag color={value === "active" ? "success" : "error"}>
          {value.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value, _) => moment(value).format("DD MMMM YYYY, HH:mm"),
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value, _) => moment(value).format("DD MMMM YYYY, HH:mm"),
    },
    {
      title: "ACTION",
      key: "action",
      type: "actions",
      actions: [
        isUserHasPermission([FEATURE + ".UPDATE"])
          ? {
              key: "update",
              label: "Update",
              onPress: (id) =>
                navigate("/master-report/email-receiver/" + id + "/edit"),
            }
          : undefined,
        isUserHasPermission([FEATURE + ".DELETE"])
          ? {
              key: "archive",
              label: "Delete",
              color: "#ff4d4f",
              disabled: (id) => isArchived(id),
              disabledTooltip: "This data is already archived",
              onPress: (id) => handleDelete(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + ".RESTORE"])
          ? {
              key: "restore",
              label: "Restore",
              color: "#1890ff",
              disabled: (id) => !isArchived(id),
              disabledTooltip: "This data not in archived",
              onPress: (id) => handleRestore(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + ".FORCE_DELETE"])
          ? {
              key: "delete",
              label: "Force Delete",
              onPress: (id) => handleDelete(id, true),
            }
          : undefined,
      ],
    },
  ];

  const columnsTemplate: IAppTableColumn<ReportEmailTemplateProperties>[] = [
    {
      title: "TEMPLATE NAME",
      dataIndex: "templateName",
      key: "templateId",
      render: (value, records) => (
        <LinkTable
          onPress={() =>
            navigate(
              "/master-report/email-template/" + records.templateId + "/edit"
            )
          }
        >
          {records.deletedAt ? (
            <Tag color="#ff5500" className="mb-2">
              Archived
            </Tag>
          ) : (
            false
          )}
          {records?.templateName}
        </LinkTable>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (value, _) => (
        <Tag color={value ? "success" : "error"}>
          {value ? "ACTIVE" : "INACTIVE"}
        </Tag>
      ),
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value, _) => moment(value).format("DD MMMM YYYY, HH:mm"),
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value, _) => moment(value).format("DD MMMM YYYY, HH:mm"),
    },
    {
      title: "ACTION",
      key: "action",
      type: "actions",
      actions: [
        isUserHasPermission([FEATURE + ".UPDATE"])
          ? {
              key: "update",
              label: "Update",
              onPress: (id) =>
                navigate("/master-report/email-template/" + id + "/edit"),
            }
          : undefined,
        isUserHasPermission([FEATURE + ".DELETE"])
          ? {
              key: "archive",
              label: "Delete",
              color: "#ff4d4f",
              disabled: (id) => isArchived(id),
              disabledTooltip: "This data is already archived",
              onPress: (id) => handleDelete(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + ".RESTORE"])
          ? {
              key: "restore",
              label: "Restore",
              color: "#1890ff",
              disabled: (id) => !isArchived(id),
              disabledTooltip: "This data not in archived",
              onPress: (id) => handleRestore(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + ".FORCE_DELETE"])
          ? {
              key: "delete",
              label: "Force Delete",
              onPress: (id) => handleDelete(id, true),
            }
          : undefined,
      ],
    },
  ];

  const columnsEmailTargetAsset: IAppTableColumn<ReportEmailTargetAssetProperties>[] =
    [
      {
        title: "ASSET",
        dataIndex: "name",
        key: "reportEmailTargetAssetId",
        render: (value, records) => (
          <LinkTable
            onPress={() =>
              navigate(
                "/master-report/email-target-by-asset/" +
                  records.reportEmailTargetAssetId +
                  "/edit"
              )
            }
          >
            {records.deletedAt ? (
              <Tag color="#ff5500" className="mb-2">
                Archived
              </Tag>
            ) : (
              false
            )}
            {records?.asset?.name}
          </LinkTable>
        ),
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        render: (value, _) => (
          <Tag color={value === "active" ? "success" : "error"}>
            {value.toUpperCase()}
          </Tag>
        ),
      },
      {
        title: "CREATED AT",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (value, _) => moment(value).format("DD MMMM YYYY, HH:mm"),
      },
      {
        title: "UPDATED AT",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (value, _) => moment(value).format("DD MMMM YYYY, HH:mm"),
      },
      {
        title: "ACTION",
        key: "action",
        type: "actions",
        actions: [
          isUserHasPermission([FEATURE + ".UPDATE"])
            ? {
                key: "update",
                label: "Update",
                onPress: (id) =>
                  navigate(
                    "/master-report/email-target-by-asset/" + id + "/edit"
                  ),
              }
            : undefined,
          isUserHasPermission([FEATURE + ".DELETE"])
            ? {
                key: "archive",
                label: "Delete",
                color: "#ff4d4f",
                disabled: (id) => isArchived(id),
                disabledTooltip: "This data is already archived",
                onPress: (id) => handleDelete(id),
              }
            : undefined,
          isUserHasPermission([FEATURE + ".RESTORE"])
            ? {
                key: "restore",
                label: "Restore",
                color: "#1890ff",
                disabled: (id) => !isArchived(id),
                disabledTooltip: "This data not in archived",
                onPress: (id) => handleRestore(id),
              }
            : undefined,
          isUserHasPermission([FEATURE + ".FORCE_DELETE"])
            ? {
                key: "delete",
                label: "Force Delete",
                onPress: (id) => handleDelete(id, true),
              }
            : undefined,
        ],
      },
    ];

  const filterCompanyOptions = React.useMemo(() => {
    const data = companies.map((item) => ({
      value: item.companyId,
      label: item.name,
    }));
    return [{ value: "", label: "All" }, ...data];
  }, [companies]);

  const filterData: any = [
    {
      type: "search",
      key: "search",
      label: "Search",
      placeholder: `Search by ${
        activeTab === 1 ? "template name" : activeTab === 2 ? "email" : "asset"
      }`,
      onChange: (value: any) => {
        handleSearch(value);
      },
      colSpan: isUserHasPermission([FEATURE + ".DELETE"]) ? 6 : 10,
    },
    {
      type: "select",
      key: "company",
      label: "Company",
      options: filterCompanyOptions,
      value:
        pageQueries[
          `${
            activeTab === 1
              ? "externalId"
              : activeTab === 2
              ? "filterCompanyId"
              : "filterCompanyId"
          }`
        ] || "All",
      onChange: (value: any) => {
        filterDropdown({
          [`${
            activeTab === 1
              ? "externalId"
              : activeTab === 2
              ? "filterCompanyId"
              : "filterCompanyId"
          }`]: value === "All" ? "" : value,
        });
      },
      colSpan: 6,
    },
    {
      type: "select",
      key: "status",
      label: "Status",
      options: [
        {
          value: "All",
          label: "All",
        },
        {
          value: `${activeTab === 1 ? true : "active"}`,
          label: "Active",
        },
        {
          value: `${activeTab === 1 ? false : "inactive"}`,
          label: "Inactive",
        },
      ],
      onChange: (value: any) => {
        filterDropdown({
          [`${activeTab === 1 ? "status" : "filterStatus"}`]:
            value === "all" ? "" : value,
        });
      },
      colSpan: 6,
    },
    {
      type: "select",
      key: "filterData",
      label: "Filter Data",
      options: [
        {
          value: "default",
          label: "Default",
        },
        {
          value: "show",
          label: "Include Archive Data",
        },
      ],
      onChange: (value: any) => {
        filterDropdown({
          filterArchived: value === "default" ? "" : value,
        });
      },
      colSpan: 4,
    },
  ].filter(
    (item) =>
      !(item.key === "filterData" && activeTab === 1) &&
      (!isUserHasPermission([FEATURE + ".DELETE"])
        ? item.key !== "filterData"
        : true)
  );

  useEffect(() => {
    getListCompanies();
  }, []);

  const onExpand = (expanded: any, record: any) => {
    if (expanded) {
      // setMasterBank(record);
      // setProgramKprs(record?.kpr);
      setExpandedRowKey(record.bankId);
    } else {
      // setProgramKprs([]);
      // setMasterBank(initialMasterBank);
      setExpandedRowKey(null);
    }
  };

  const _renderEmailReceivers = () => {
    return (
      <div style={{ marginTop: 15 }}>
        <RowFilter
          filterValues={{
            search: pageQueries.search,
            status: pageQueries.filterStatus || "All",
            filterData: pageQueries.filterData || "default",
            company: company.name || "all",
          }}
          filters={[filterData]}
        />
        <AppTable
          isLoading={isLoading}
          keyId="reportEmailReceiverId"
          columns={columnsEmail}
          data={data}
          pagination={pagination}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    );
  };

  const _renderTemplateEmail = () => {
    return (
      <div style={{ marginTop: 15 }}>
        <RowFilter
          filterValues={{
            search: pageQueries.search,
            status: pageQueries.filterStatus || "All",
            filterData: pageQueries.filterData || "default",
            company: company.name || "all",
          }}
          filters={[filterData]}
        />
        <AppTable
          isLoading={isLoading}
          keyId="templateId"
          columns={columnsTemplate}
          data={data}
          pagination={pagination}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    );
  };

  const _renderEmailTargetAsset = () => {
    return (
      <div style={{ marginTop: 15 }}>
        <RowFilter
          filterValues={{
            search: pageQueries.search,
            status: pageQueries.filterStatus || "All",
            filterData: pageQueries.filterData || "default",
            company: company.name || "all",
          }}
          filters={[filterData]}
        />
        <AppTable
          isLoading={isLoading}
          keyId="reportEmailTargetAssetId"
          columns={columnsEmailTargetAsset}
          data={data}
          pagination={pagination}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    );
  };

  const items: any = [
    {
      label: `Email Template`,
      key: 1,
      children: _renderTemplateEmail(),
    },
    {
      label: `Email Receivers`,
      key: 2,
      children: _renderEmailReceivers(),
    },
    {
      label: `Email Target by Asset`,
      key: 3,
      children: _renderEmailTargetAsset(),
    },
  ];

  useEffect(() => {
    setData([]);
    fetchList();
    handleSearch("");
  }, [activeTab]);

  return (
    <React.Fragment>
      <HeaderSection
        title={"Master Report Email"}
        rightAction={[
          isUserHasPermission([FEATURE + ".CREATE"]) ? (
            <Button type="primary" onClick={handleCreate}>
              Add{" "}
              {activeTab === 1
                ? "Template"
                : activeTab === 2
                ? "Email"
                : "Target Email"}
            </Button>
          ) : (
            false
          ),
        ]}
      />

      <Tabs
        type="card"
        // defaultActiveKey={`${queryParams.get('activeTab') || activeTab}`}
        onChange={(e) => setActiveTab(e)}
        items={items}
      />

      <Modal
        title={
          forceDeleteId ? "Force Delete Confirmation" : "Delete Confirmation"
        }
        open={isModalDeleteOpen}
        onOk={deleteData}
        okButtonProps={{
          style: {
            background: "red",
          },
        }}
        onCancel={handleCancelDelete}
      >
        {forceDeleteId ? (
          <p>
            Are you sure want to permanently delete this data? This data will be
            deleted and only can't be undo.
          </p>
        ) : (
          <p>
            Are you sure want to delete this data? This data will be archived
            and only User with restore permission that can undo this action.
          </p>
        )}
      </Modal>

      <Modal
        title="Restore Confirmation"
        open={isModalRestoreOpen}
        onOk={restoreData}
        okButtonProps={{
          style: {
            background: "blue",
          },
        }}
        onCancel={handleCancelDelete}
      >
        <p>Are you sure want to restore this data?.</p>
      </Modal>
    </React.Fragment>
  );
};
export default MasterReport;
