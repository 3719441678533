import { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props extends PropsWithChildren {
  onPress: () => void;
}

const LinkTable: React.FC<Props> = ({ children, onPress }) => {
  return <Text onClick={onPress}>{children}</Text>;
};

const Text = styled.div`
  text-decoration: underline;
  color: var(--button-primary-color);
  cursor: pointer;
`;

export default LinkTable;
