import {
  BaseResponseProps,
  DetailItem,
  HeaderSection,
  IAppTableColumn,
  Label,
} from "@qlibs/react-components";
import { AssetProperties, initialAsset } from "./types/asset.type";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useNavigate, useParams } from "react-router-dom";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import React from "react";
import { httpRequest } from "../../helpers/api";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import SectionContent from "../../components/SectionContent";
import { generateAddress } from "../../helpers/address";
import { ColumnsType } from "antd/lib/table";

const moment = require("moment");

interface ILocation {
  assetId: string;
}

interface ResponseProps
  extends BaseResponseProps<
    Omit<AssetProperties, "createdAt" | "updatedAt" | "companies">
  > {}

const AssetDetail = () => {
  const navigate = useNavigate();
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const { assetId } = useParams<keyof ILocation>() as ILocation;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<AssetProperties>({
    ...initialAsset,
  });

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>("/asset/" + assetId);
        if (!res) {
          message.error("Something went wrong");
          return;
        }

        setData({
          ...res.data.payload,
        });

        let label = `Detail Asset`;
        const bcDetails = [
          {
            field: "assetId",
            value: assetId,
            label,
          },
        ];
        setBreadcrumbDetails(bcDetails);

        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchUser();

    // eslint-disable-next-line
  }, [assetId]);

  const handleClickEdit = () => {
    navigate("/asset/" + assetId + "/edit");
  };

  const columns: ColumnsType<any> = [
    // {
    //   title: "Image",
    //   dataIndex: "imageLink",
    //   key: "imageLink",
    //   width: 70,
    //   render: (value: string, _: any) => (
    //     <Image
    //       width={60}
    //       height={60}
    //       style={{
    //         objectFit: "contain",
    //         border: "thin solid #ddd",
    //         overflow: "hidden",
    //         background: "#dadada",
    //         borderRadius: 4,
    //       }}
    //       src={value}
    //       fallback="/images/flag-not-found.png"
    //     />
    //   ),
    // },
    {
      title: "Device Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Device Unique ID",
      dataIndex: "uniqueId",
      key: "uniqueId",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string, _: any) => (
        <DetailItem
          label=""
          value={value.toUpperCase()}
          type="tag"
          _tagProps={{
            color: value === "active" ? "success" : "error",
          }}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Detail Asset"}
        subtitle={`Manage your asset data`}
        rightAction={
          <Space>
            <Button
              disabled={data.deletedAt ? true : false}
              type="primary"
              onClick={handleClickEdit}
            >
              Edit Asset
            </Button>
          </Space>
        }
      />

      <Spin spinning={isLoading}>
        <Card>
          {data?.deletedAt ? (
            <Alert
              className="mb-5"
              type="warning"
              message="This data has been archived and cannot be edited"
            />
          ) : (
            false
          )}
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about asset"
          >
            <Row>
              <Col span={24}>
                <Label value="Image" />
                <div
                  style={{
                    width: "100%",
                    height: 250,
                    background: "#f4f6f9",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 4,
                  }}
                >
                  <Image
                    width={"auto"}
                    height={250}
                    fallback="/images/empty-image.png"
                    src={data.imageLink}
                  />
                </div>
              </Col>
              <Col span={12}>
                <DetailItem label="Name" value={data.name} />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Company"
                  value={data.company?.name || "N/A"}
                />
              </Col>
              <Col span={12}>
                <DetailItem label="PIC Name" value={data.picName || "N/A"} />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="PIC Phone Number"
                  value={data.picPhoneNumber || "N/A"}
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Created by"
                  value={data?.userCreator?.name || "N/A"}
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Created at"
                  value={moment(data.createdAt).format("DD MMMM YYYY, HH:mm")}
                  // type='datetime'
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Updated at"
                  value={moment(data.updatedAt).format("DD MMMM YYYY, HH:mm")}
                  // type='datetime'
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Status"
                  value={data.status.toUpperCase()}
                  type="tag"
                  _tagProps={{
                    color: data.status === "active" ? "success" : "error",
                  }}
                />
              </Col>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle="Satellite & ID"
            subTitle="These are asset information, you can change anything"
          >
            <Row>
              <Col span={12}>
                <DetailItem
                  label="GSM Number"
                  value={data.gsmNumber || "N/A"}
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="IMEI Number"
                  value={data.imeiNumber || "N/A"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DetailItem
                  label="Satellite ID"
                  value={data.satelliteId || "N/A"}
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Satellite Device ID"
                  value={data.satelliteDeviceId || "N/A"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DetailItem
                  label="Satellite Operator"
                  value={data.satelliteOperator || "N/A"}
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label="Installation Date"
                  value={
                    moment(data.installationAt).format("DD MMMM YYYY, HH:mm") ||
                    "N/A"
                  }
                  // type={'datetime'}
                />
              </Col>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle="Devices"
            subTitle="These are asset information, you can change anything"
          >
            <Table
              columns={columns}
              dataSource={data.devices}
              pagination={false}
            />
          </SectionContent>
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default AssetDetail;
