export type GroupProperties = {
  groupId: string;
  name: string;
  nickname: string;
  address?: AddressProperties;
  phoneNumber?: string;
  fax?: string;
  status: string;
  createdByUserId: string;
  userCreator?: {
    name?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
  };
  userId: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  companies?: {
    companyId: string;
    name: string;
    imageLink: string;
  }[];
};

export type AddressProperties = {
  province: {
    id: number;
    value: string;
  };
  city: {
    id: number;
    value: string;
  };
  district: {
    id: number;
    value: string;
  };
  address: string;
};

export const initialGroup: GroupProperties = {
  groupId: "",
  name: "",
  nickname: "",
  phoneNumber: "",
  status: "",
  createdByUserId: "",
  userId: "",
  companies: [],
};

export interface ICreateGroup {
  name: string;
  nickname: string;
  phoneNumber?: string;
  province?: number;
  city?: number;
  district?: number;
  address?: string;
  fax?: string;
  status: string;
}
